import { Col, Grid, Modal, Row } from "antd";
import React from "react";
import ReactGA from "react-ga4";
import bgSand from "../../assets/images/bg-menu-sand.png";
import sandMB from "../../assets/images/sand-mb.png";
import waterSplashMBTh from "../../assets/images/water-splash-mb-th.png";
import waterSplashMB from "../../assets/images/water-splash-mb.png";
import ButtonBackSmall from "../../components/button/ButtonBackSmall";
import ButtonCloseSmall from "../../components/button/ButtonCloseSmall";
import CustomCard from "../../components/card/CustomCard";
import ContentCenter from "../../components/content-center/ContentCenter";
"../../components/copyright/Copyright";
import MainBackground from "../../components/main-background/MainBackground";
import { useMain } from "../../context/MainContext";
import "./style.css";

const Menu = () => {
    const { language, translations, PAGE_STATE, MENU_NAME, switchMenu, setCurrentPage } = useMain();
    const handleClick = (currentPage, menuName) => {
        ReactGA.event({
            category: "user action",
            action: "select menu",
            label: menuName
        });
        switchMenu(menuName)
        setCurrentPage(currentPage)
    }

  let bgSplash = require(`../../assets/images/bg-menu-splash.png`);
  const screens = Grid.useBreakpoint();

  if (language === "th") {
    bgSplash = require(`../../assets/images/bg-menu-splash-th.png`);
  }

  return (
    <MainBackground className="bg-container">
      <Modal
        open={true}
        closable={false}
        width={800}
        footer={null}
        className="wrap-modal"
      >
        <ContentCenter padding="0 20px">
          <div
            style={{ position: "absolute", left: 10, top: -20 }}
            className="show-767"
          >
            <ButtonBackSmall
              size="middle"
              onClick={() => handleClick(PAGE_STATE[1])}
              message=""
              shape="circle"
            />
          </div>
          <div
            style={{ position: "absolute", right: 10, top: -20 }}
            className="show-767"
          >
            <ButtonCloseSmall
              size="middle"
              onClick={() => handleClick()}
              message=""
              shape="circle"
            />
          </div>

          <Row
            justify="center"
            align="middle"
            style={{ position: "relative" }}
            gutter={[40, 0]}
          >
            <div
              style={{ position: "absolute", left: 20, top: -20 }}
              className="hide-767"
            >
              <ButtonBackSmall
                size="middle"
                onClick={() => handleClick(PAGE_STATE[1])}
                message={translations[language].menu.button.back}
              />
            </div>
            <div
              style={{ position: "absolute", right: 20, top: -20 }}
              className="hide-767"
            >
              <ButtonCloseSmall
                size="middle"
                onClick={() => handleClick()}
                message={translations[language].menu.button.close}
              />
            </div>
            <Col xs={24}>
              <div style={{ marginBottom: 30 }}></div>
            </Col>
            <div className={'card-container-menu'} style={{ "--grid-column": screens?.xs ? "100%" : "calc(50% - 10px) calc(50% - 10px)", "--menu-padding": screens?.xs ? "0 8px" : "0 20px" }}>
              <CustomCard
                key={1}
                src={screens?.xs ? language === "th" ? waterSplashMBTh : waterSplashMB : bgSplash}
                title={translations[language].menu.m1.h1}
                description={translations[language].menu.m1.d1}
                width="100%"
                height="100%"
                onClick={() => handleClick(PAGE_STATE[4], MENU_NAME[0])}
                imgRadius="32px 32px 0 0"
              />
              <CustomCard
                key={2}
                src={screens?.xs ? sandMB : bgSand}
                title={translations[language].menu.m2.h1}
                description={translations[language].menu.m2.d1}
                width="100%"
                height="100%"
                onClick={() => handleClick(PAGE_STATE[3], MENU_NAME[1])}
                imgRadius="32px 32px 0 0"
              />
            </div>
          </Row>
        </ContentCenter>
        
      </Modal>
    </MainBackground>
  );
};

export default Menu;
