import React from "react";
import { Col, Typography } from "antd";
import ContentCenter from "../../components/content-center/ContentCenter";
import MainBackground from "../../components/main-background/MainBackground";
import ButtonGetstart from "../../components/button/ButtonGetStart";
import { useMain } from "../../context/MainContext";
import logo from "../../assets/images/accenture_logo.svg";
import Copyright from "../../components/copyright/Copyright";

const Home = () => {
  const { language, translations, goNextPage } = useMain();
  const handleButtonClick = () => {
    goNextPage();
  };

  return (
    <MainBackground className="bg-container-blur">
      <ContentCenter height="90%">
        <Col justify="center" align="middle">
          <Typography className="p-32-700 hide-767 title-home">
            <img src={logo} alt="Accenture" />
          </Typography>
          <Typography className="p-28-700 show-767 title-home">
            <img src={logo} alt="Accenture" />
          </Typography>
          <Typography className="p-48-700 pink-hex hide-767 body-home">
            {translations[language].home.m1.h1}
          </Typography>
          <Typography className="p-40-700 pink-hex show-767 body-home">
            {translations[language].home.m1.h1}
          </Typography>
          <Typography className="p-32-400 hide-767 detail-home">
            {translations[language].home.m1.d1
              .split("soaked")
              .map((part, index, array) => (
                <React.Fragment key={index}>
                  {part}
                  {index !== array.length - 1 && "soaked"}
                  {index !== array.length - 1 && <br />}
                </React.Fragment>
              ))}
          </Typography>
          <Typography className="p-20-400 show-767">
            {translations[language].home.m1.d1}
          </Typography>
        </Col>
        <Col style={{ width: "100%" }} align="middle">
          <ButtonGetstart
            message={translations[language].home.button.b1}
            className="button-get-start-style"
            onClick={handleButtonClick}
            size="large"
          />
        </Col>
      </ContentCenter>
      <Copyright />
    </MainBackground>
  );
};

export default Home;
