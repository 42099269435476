import React from "react";
import "./style.css";
import { Button } from "antd";

const CustomButton = (props) => {
  return (
    <Button
      size={props?.size || "middle"}
      className={props.style}
      onClick={props.onClick}
      style={{
        height: props.height ?? null,
        width: props.width ?? null,
        backgroundColor:
          props?.isCloseHover &&
          (props.style === "white-style" ? "white" : "#e804fc"),
        color:
          props?.isCloseHover &&
          (props.style === "white-style" ? "#e804fc" : "white"),
        border: props?.isCloseHover && "1.5px solid #e804fc",
      }}
      disabled={props.disabled}
    >
      <span style={{ fontWeight: "bold" }}>{props.text}</span>
    </Button>
  );
};

export default CustomButton;
