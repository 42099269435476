import { React, useEffect } from 'react'
import ReactGA from "react-ga4";
import Main from './pages/main/Main';
import { MainProvider } from './context/MainContext';
import { PagodaProvider } from './context/PagodaContext';

function App() {
  useEffect(() => {
    ReactGA.initialize(`${process.env.REACT_APP_GA_TRACKING_ID}`);
  }, []);

  return (
    <MainProvider>
      <PagodaProvider>
        <Main />
      </PagodaProvider>
    </MainProvider>

  );
}

export default App;