import React, { useEffect, useState, useRef } from "react";
import { Col, Row, Modal } from "antd";
import { uploadBlob } from "../../services/modal/api";
import {
  FacebookShareButton,
  LineShareButton,
  TwitterShareButton,
} from "react-share";
import facebookIcon from "../../assets/images/facebook.png";
import lineIcon from "../../assets/images/line.png";
import XIcon from "../../assets/images/x.png";
import CustomButton from "../../components/button/CustomButton";
import {
  getWaterFestivalBlessingWord,
  postSandPagodaRandom,
} from "../../services/modal/api";
import { sustainable } from "../../services/modal/modalState";
import { useMain } from "../../context/MainContext";
import Generate from "../../components/generate/Generate";
import ShareImage, { handleClickDownloadCard } from "./ShareImage";
import { usePagodaContext } from "../../context/PagodaContext";
import ContentCenter from "../../components/content-center/ContentCenter";
import html2canvas from "html2canvas";
import Error from "../error/Error";
import ReactGA from "react-ga4";
import MainBackground from "../../components/main-background/MainBackground";
import "./style.css";

const Result = () => {
  const {
    language,
    translations,
    menu,
    goNextPage,
    requestId,
    sessionId,
    MENU_NAME,
  } = useMain();
  const { pagodaPayload } = usePagodaContext();
  const [blessingWord, setBlessingWord] = useState("");
  const [sustainWord, setSustainWord] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorPage, setErrorPage] = useState(false);
  const initialized = useRef(false);
  const [titleWord, setTitleWord] = useState(false);
  const [shareWord, setShareWord] = useState(false);
  const lineUrl = "https://esongkran.acnthcares.com/";
  const [urlImage, setUrlImage] = useState();
  const isFirefox = (navigator.userAgent.indexOf("Firefox") != -1) ? true : false;
  const isLine = (navigator.userAgent.includes("Line")) ? true : false;

  const handleShare = async (requestId, sessionId) => {
    const element = document.getElementById("image-card"),
      canvas = await html2canvas(element),
      data = canvas.toDataURL("image/png");
      

    const base64 = data;

    fetch(base64)
      .then((res) => res.blob())
      .then(async (blob) => {
        const fd = new FormData();
        const file = new File([blob], `Songkran${sessionId}.png`, {
          type: "image/png",
        });
        fd.append("file", file);
        const response = await uploadBlob(requestId, sessionId, fd);
        setUrlImage(response.urlPath);
        // setUrlImage(response.urlPath);
        setLoading(false);
      });
  };

  const handleClick = () => {
    ReactGA.event({
      category: "user action",
      action: "result: click to pagoda parade",
    });
    goNextPage();
  };

  const createSustain = () => {
    if (sustainable[pagodaPayload.celebrateWith].length > 0) {
      const getSustain =
        sustainable[pagodaPayload.celebrateWith][
        Math.floor(
          Math.random() * sustainable[pagodaPayload.celebrateWith].length
        )
        ][language];
      setSustainWord(getSustain);
    }
  };

  useEffect(() => {
    setLoading(true);
    createSustain();
    if (blessingWord.length > 0) {
      handleShare(requestId, sessionId);
    }
  }, [blessingWord]);

  if (menu === MENU_NAME[0]) {
    const params = {
      name: "SongKran",
      location: pagodaPayload.location,
      celebrateWith: pagodaPayload.celebrateWith,
      food: pagodaPayload.food,
      activity: pagodaPayload.activity,
    };

    const createTitleWord = async () => {
      setTitleWord(translations[language].result.title);
    };

    const createShareWord = async () => {
      setShareWord(translations[language].result.social);
    };

    const createBlessing = async () => {
      try {
        // setLoading(true);
        const response = await getWaterFestivalBlessingWord(
          language,
          requestId,
          sessionId,
          params
        );

        if (response?.blessingWord !== undefined) {
          setBlessingWord(response.blessingWord);
          setErrorPage(false);
          // handleShare(requestId, sessionId);
        } else {
          setErrorPage({
            error: true,
            status: response?.response?.status || "timeout",
          });
        }
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
        return e;
      }
    };

    useEffect(() => {
      if (!initialized.current) {
        initialized.current = true;
        createTitleWord();
        createShareWord();
        createBlessing();
      }

    }, []);
  }

  if (menu === MENU_NAME[1]) {
    const params = {
      name: pagodaPayload.name,
      location: pagodaPayload.location,
      celebrateWith: pagodaPayload.celebrateWith,
      food: pagodaPayload.food,
      activity: pagodaPayload.activity,
      pagodaType: pagodaPayload.pagodaType,
      flag: pagodaPayload.flag,
      flower: pagodaPayload.flower,
      avatar: {
        icon: pagodaPayload.avatar.icon,
        face: pagodaPayload.avatar.face,
        neck: pagodaPayload.avatar.neck,
        hairType1: pagodaPayload.avatar.hairType1,
        hairType2: pagodaPayload.avatar.hairType2,
        hairType3: pagodaPayload.avatar.hairType3,
        hairType4: pagodaPayload.avatar.hairType4,
      },
    };

    const createTitleWord = async () => {
      setTitleWord(translations[language].result.title_pagoda);
    };

    const createShareWord = async () => {
      setShareWord(translations[language].result.social_pagoda);
    };

    const createBlessing = async () => {
      try {
        // setLoading(true);
        const response = await postSandPagodaRandom(
          language,
          requestId,
          sessionId,
          params
        );
        if (response?.blessWord !== undefined) {
          setBlessingWord(response.blessWord);
          setErrorPage(false);
          // handleShare(requestId, sessionId);
        } else {
          setErrorPage({
            error: true,
            status: response?.response?.status || "timeout",
          });
        }
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
        return e;
      }
    };

    useEffect(() => {
      if (!initialized.current) {
        initialized.current = true;
        createTitleWord();
        createShareWord();
        createBlessing();
      }

    }, []);
  }

  const ResultContainer = () => {
    if (loading) {
      return (
        <div>
          <Generate
            title={translations[language].generate.title}
            subTitle={translations[language].generate.subtitle}
          ></Generate>
        </div>
      );
    } else {
      return (
        <MainBackground className="bg-container">
          <div>
            <Modal open={true} closable={false} width={1186} footer={null}>
              <ContentCenter>
                <div className={loading ? "displayNone" : "displayShow"}>
                  <Col span={24} className="card-container">
                    <ShareImage
                      language={language}
                      titleWord={titleWord}
                      blessingWord={blessingWord}
                      sustainWord={sustainWord}
                      location={pagodaPayload.location}
                      celebrateWith={pagodaPayload.celebrateWith}
                      pagodaType={pagodaPayload.pagodaType}
                      flag={pagodaPayload.flag}
                      flower={pagodaPayload.flower}
                      id="image-container-card"
                    />
                  </Col>

                  <Row className={isFirefox && language === "th" ? "displayNone" : "wrapper-share"}>
                    <Col
                      xs={24}
                      md={12}
                      lg={12}
                      align="middle"
                      className="wrapper-share-text"
                    >
                      <p className="share-text">{shareWord}</p>
                    </Col>
                    <Col
                      xs={24}
                      md={12}
                      lg={11}
                      align="middle"
                      className="wrapper-share-icon"
                    >
                      <FacebookShareButton
                        url={urlImage}
                        title="Songkran Celebration!"
                        description="Enjoy the festivities without getting soaked with Accenture’s Generative AI"
                      >
                        <img
                          src={facebookIcon}
                          alt="Share on Facebook"
                          className="icon-social"
                        />
                      </FacebookShareButton>
                    </Col>
                  </Row>

                  <Row className="wrapper-share line2">
                    <Col
                      xs={24}
                      md={12}
                      lg={12}
                      align="middle"
                      className="wrapper-share-text"
                    >
                      <p className="share-text">{translations[language].result.social_invite}</p>
                    </Col>
                    <Col
                      xs={24}
                      md={12}
                      lg={11}
                      align="middle"
                      className="wrapper-share-icon"
                    >
                      <LineShareButton
                        url={lineUrl}
                        title="Songkran Celebration!"
                      >
                        <img
                          src={lineIcon}
                          alt="Share on Line"
                          className="icon-social"
                        />
                      </LineShareButton>
                      <TwitterShareButton
                        url={lineUrl}
                        title="Songkran Celebration!"
                        ref={document.getElementById("image-card")}
                      // media={urlImage}
                      >
                        <img
                          src={XIcon}
                          alt="Share on X"
                          className="icon-social"
                        />
                      </TwitterShareButton>
                    </Col>
                  </Row>
                  <Row className={isLine ? "displayNone" : "wrapper-button"}>
                    <Col
                      xs={24}
                      md={12}
                      lg={12}
                      align="middle"
                      className="custombutton-a"
                    >
                      <CustomButton
                        style="white-style"
                        text={translations[language].result.save}
                        onClick={() => handleClickDownloadCard()}
                        size="large"
                        width="100%"
                      />
                    </Col>
                    <Col
                      xs={24}
                      md={12}
                      lg={11}
                      align="middle"
                      className="custombutton-b"
                    >
                      <CustomButton
                        style="pink-style"
                        text={translations[language].result.done}
                        onClick={() => handleClick()}
                        size="large"
                        width="100%"
                      />
                    </Col>
                  </Row>

                  <Row className={isLine ? "wrapper-button" : "displayNone"}>
                    <Col style={{ width: "100%" }} align="middle">
                      <CustomButton
                        style="pink-style"
                        text={translations[language].result.done}
                        onClick={() => handleClick()}
                        size="large"
                        width="100%"
                      />
                    </Col>
                  </Row>

                </div>
              </ContentCenter>
            </Modal>
          </div>
        </MainBackground>
      );
    }
  };

  return (
    <div>
      {errorPage ? (
        <Error error={errorPage.error} status={errorPage.status} />
      ) : (
        <ResultContainer />
      )}
    </div>
  );
};

export default Result;
