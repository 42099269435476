import React from "react";
import ContentCenter from "../../components/content-center/ContentCenter";
import { Col, Modal, Grid, Row } from "antd";
import MainBackground from "../../components/main-background/MainBackground";
import { useMain } from "../../context/MainContext";
import CustomButton from "../../components/button/CustomButton";
import CardDetails from "../../components/card-details/CardDetails";
import icePagoda from "../../assets/images/pagoda/ice-pagoda.svg";
import stonePagoda from "../../assets/images/pagoda/stone-pagoda.svg";
import sandPagoda from "../../assets/images/pagoda/sand-castle-pagoda.svg";
import { sandPagodaType } from "../../services/modal/modalState";
import { usePagodaContext } from "../../context/PagodaContext";
import HeaderTitle from "../../components/header-title/HeaderTitle";
import ReactGA from "react-ga4";
import "./style.css";

const PagodaSelect = () => {
  const { language, translations, goNextPage } = useMain();
  const { pagodaPayload } = usePagodaContext();
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  const SAND_CASTLE = "SAND_CASTLE";
  const STONE = "STONE";
  const ICE = "ICE";
  const randomPagoda =
    sandPagodaType[Math.floor(Math.random() * sandPagodaType.length)];
  const pagodaName = randomPagoda.name;
  const pagodaTitle = randomPagoda.title[language];
  const pagodaDetails = randomPagoda.description[language];
  let pagodaImage = sandPagoda;

  if (pagodaName === SAND_CASTLE) {
    pagodaImage = sandPagoda;
  }
  if (pagodaName === STONE) {
    pagodaImage = stonePagoda;
  }
  if (pagodaName === ICE) {
    pagodaImage = icePagoda;
  }

  const handleClick = () => {
    ReactGA.event({
      category: "user action",
      action: "select pagoda type",
      label: pagodaName,
    });
    pagodaPayload.pagodaType = pagodaName;
    goNextPage();
  };

  return (
    <MainBackground className="bg-container">
      <Modal
        className="pagoda-select-wrapper"
        open={true}
        closable={false}
        width={800}
        footer={null}
      >
        <ContentCenter paddingLeft={0} paddingRight={0}>
          <Col xs={24} align="middle">
            <HeaderTitle
              title={translations[language].pagoda.pagoda_page.title}
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            align="center"
            style={{ marginBottom: xs ? "83px" : "128px" }}
          >
            <CardDetails
              imageWidth={174}
              imageHeight={174}
              imageSrc={pagodaImage}
              altImage={"Pagoda Image"}
              title={pagodaTitle}
              details={pagodaDetails}
            />
          </Col>
          <Row
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Col
              align="middle"
              className={xs && "bottom-button"}
              style={{
                width: xs ? "100%" : "558px",
                paddingLeft: xs && "16px",
                paddingRight: xs && "16px",
              }}
            >
              <CustomButton
                style="pagoda-next-style"
                isCloseHover={xs}
                height={xs ? 48 : null}
                text={translations[language].pagoda.pagoda_page.next}
                onClick={() => handleClick()}
                size="large"
              />
            </Col>
          </Row>
        </ContentCenter>
      </Modal>
    </MainBackground>
  );
};

export default PagodaSelect;
