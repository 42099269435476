// import CustomButton from "../../components/button/CustomButton";
import "./Error.style.css";
import { Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import React from "react";
import { useMain } from "../../context/MainContext";
import ButtonBack from "../../components/button/ButtonBack";

import MainBackground from "../../components/main-background/MainBackground";

export default function Error({ error, status }) {
  const { language, goHomePage } = useMain();

  const isSafari =
    navigator.vendor &&
    navigator.vendor.indexOf("Apple") > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf("CriOS") === -1 &&
    navigator.userAgent.indexOf("FxiOS") === -1;

  const handleClick = () => {
    goHomePage();
  };

  const ErrorTH = () => {
    const errorMessageTH = "ขออภัย สงสัยน้ำอาจจะทำให้ระบบขัดข้อง"
    const btnTH = "ลองอีกครั้ง"


    return (
      <MainBackground className="bg-container">
        <Modal open={error} footer={false} closeIcon={false} centered className="er-container">
          <div className={isSafari ? "errorpopupsafari" : "errorpopup"}>
            <ExclamationCircleFilled className="warning-icon" />
            <div>
              <div className="head">{errorMessageTH}</div>
              {/* <div className="second">{errorMessageTH}</div> */}
            </div>

            <ButtonBack
              onClick={() => handleClick()}
              text={btnTH}
            >
            </ButtonBack>
          </div>
        </Modal>
      </MainBackground>
    );
  }

  const ErrorEN = () => {
    const headerMessage = "Sorry, it seems like";
    const headerMessage2 = "we are not waterproof";
    let errorMessageEN = ""
    const btnEN = "Try again"

    if (status?.toString() === "500") {
      errorMessageEN = "Internal server error"
    }
    else if (status?.toString() === "404") {
      errorMessageEN = "Validate request fail"
    }
    else if (status?.toString() === "timeout") {
      errorMessageEN = "Server timeout"
    }
    console.log(errorMessageEN);

    return (
      <MainBackground className="bg-container">
        <Modal open={error} footer={false} closeIcon={false} centered className="er-container">
          <div className="errorpopup">
            <ExclamationCircleFilled className="warning-icon" />
            <div>
              <div className="head">{headerMessage}<br />{headerMessage2}</div>
              {/* <div className="second">{errorMessageEN}</div> */}
            </div>

            <ButtonBack
              onClick={() => handleClick()}
              text={btnEN}
            >
            </ButtonBack>
          </div>
        </Modal>
      </MainBackground>
    );
  }

  return (
    <div>
      {language === "en" ? <ErrorEN /> : <ErrorTH />}
    </div>
  )


}
