import React from "react";
import { Col, Typography, Modal } from "antd";
import ReactGA from "react-ga4";
import ContentCenter from "../../components/content-center/ContentCenter";
import HeaderTitle from "../../components/header-title/HeaderTitle";
import { useMain } from "../../context/MainContext";
import MainBackground from "../../components/main-background/MainBackground";
import en from "../../assets/images/en.png";
import th from "../../assets/images/th.png";
import ButtonCloseSmall from '../../components/button/ButtonCloseSmall';
import { v4 as uuidv4 } from "uuid";
import "./style.css";

const Language = () => {
  const { language, translations, goNextPage, switchLanguage, switchMenu, setCurrentPage, saveSessionId, saveRequestId } = useMain();
  const handleClick = (lang, currentPage, menuName) => {
    ReactGA.event({
      category: "user action",
      action: "select language",
      label: lang
    });
    switchLanguage(lang);
    switchMenu(menuName)
    setCurrentPage(currentPage)
    saveSessionId(uuidv4());
    saveRequestId(uuidv4());
    goNextPage();
  };

  const LanguageButton = ({ text, lang }) => {
    return (
      <>
        <div className="lang-circle" onClick={() => handleClick(lang, "Language")}>
          <img src={lang === "en" ? en : th} alt="en-flag" />
        </div>
        <Typography.Title level={3} className="white-text">
          {text}
        </Typography.Title>
      </>
    );
  };

  return (
    <MainBackground className="bg-container-1" showCopyright={true}>
      <div>
        <Modal open={true} closable={false} width={800} footer={null}>
          <Col xs={20} align="end" className='hide-767'>
            <ButtonCloseSmall size='middle' onClick={() => handleClick(language, 'Parade', 'pagoda')} message={translations[language].language.button.close} />
          </Col>
          <Col xs={20} align="end" className='show-767'>
            <ButtonCloseSmall size='middle' onClick={() => handleClick(language, 'Parade', 'pagoda')} className='show-767' shape='circle' message="" />
          </Col>
          <ContentCenter>
            <Col xs={24} align="middle">
              <HeaderTitle title={translations[language].language.m1.h1} />
            </Col>
            <Col xs={12} sm={12} align="middle" style={{ padding: '1rem' }}>
              <LanguageButton text={translations[language].language.m1.d1} lang="th" />
            </Col>
            <Col xs={12} sm={12} align="middle" style={{ padding: '1rem' }}>
              <LanguageButton text={translations[language].language.m1.d2} lang="en" />
            </Col>
          </ContentCenter>
        </Modal>
      </div>
    </MainBackground>

  );
};

export default Language;