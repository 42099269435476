import React from "react";
import { Flex, Modal, Col, Row } from "antd";
import AnswerCard from "../../components/card/AnswerCard";
import ReactGA from "react-ga4";
import RestImage from "../../assets/images/activity/Rest.svg";
import GameImage from "../../assets/images/activity/PlayGames.svg";
import CleaningImage from "../../assets/images/activity/HouseCleaning.svg";
import WorkImage from "../../assets/images/activity/Work.svg";
import MainBackground from "../../components/main-background/MainBackground";
import ContentCenter from "../../components/content-center/ContentCenter";
import { useMain } from "../../context/MainContext";
import { usePagodaContext } from "../../context/PagodaContext";
import HeaderTitle from "../../components/header-title/HeaderTitle";
import "./style.css";

const QuestionActivity = () => {
  const {
    language,
    translations,
    menu,
    PAGE_STATE,
    MENU_NAME,
    goNextPage,
    setCurrentPage,
  } = useMain();
  const { pagodaPayload } = usePagodaContext();

  const handleClick = (value) => {
    pagodaPayload.activity = value;
    ReactGA.event({
      category: "user action",
      action: "select activity",
      label: value
    });
    if (menu === MENU_NAME[0]) {
      setCurrentPage(PAGE_STATE[11]);
    } else {
      goNextPage();
    }
  };

  return (
    <MainBackground className="bg-container">
      <Modal open={true} closable={false} width={1400} footer={null}>
        <ContentCenter>
          <Col xs={24} align="middle">
            <HeaderTitle
              title={translations[language].question.activity.title}
            />
          </Col>
          <Flex justify="center" wrap="wrap" gap="large">
            <Row gutter={[16, 16]}>
              <Col xs={12} lg={6}>
                <AnswerCard
                  image={RestImage}
                  title={translations[language].question.activity.a1.h1}
                  description={translations[language].question.activity.a1.d1}
                  onClick={() => handleClick("REST")}
                />
              </Col>
              <Col xs={12} lg={6}>
                <AnswerCard
                  image={GameImage}
                  title={translations[language].question.activity.a2.h1}
                  description={translations[language].question.activity.a2.d1}
                  onClick={() => handleClick("PLAY_GAMES")}
                />
              </Col>
              <Col xs={12} lg={6}>
                <AnswerCard
                  image={CleaningImage}
                  title={translations[language].question.activity.a3.h1}
                  description={translations[language].question.activity.a3.d1}
                  onClick={() => handleClick("HOUSE_CLEANING")}
                />
              </Col>
              <Col xs={12} lg={6}>
                <AnswerCard
                  image={WorkImage}
                  title={translations[language].question.activity.a4.h1}
                  description={translations[language].question.activity.a4.d1}
                  onClick={() => handleClick("WORK")}
                />
              </Col>
            </Row>
          </Flex>
        </ContentCenter>
      </Modal>
    </MainBackground>
  );
};

export default QuestionActivity;
