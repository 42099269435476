import { Col, Row, Image } from "antd";
import React from "react";
import GenAIIcon from "../../assets/images/GenAIIcon/genAI_icon.png";
import { useMain } from "../../context/MainContext";
import { domToPng } from "modern-screenshot";
import logo from "../../assets/images/accenture_logo.svg";
import qrcode from "../../assets/images/QRCode.png";
import ReactGA from "react-ga4";
import "./style.css";

export const handleClickDownloadCard = async () => {
  ReactGA.event({
    category: "user action",
    action: "result: click save image",
  });
  const element = document.getElementById("image-card"),
    canvas = await domToPng(element),
    link = document.createElement("a");

  link.href = canvas;
  link.download = "Songkran-Card";

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const ShareImage = ({
  language,
  titleWord,
  blessingWord,
  sustainWord,
  location,
  celebrateWith,
  pagodaType,
  flag,
  flower,
}) => {
  const { menu, MENU_NAME } = useMain();
  let folderName = "";
  let fileName = "";
  let imageSrc = "";
  var expTtitle = sustainWord.substr(0, sustainWord.indexOf(":"));
  var expDescript = sustainWord
    .substr(sustainWord.indexOf(":"))
    .replace(": ", "");

  // const screens = Grid.useBreakpoint();
  // console.log(screens)

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  if (menu === MENU_NAME[0]) {
    if (location === "CULTURAL_PLACES") {
      folderName = "Cultural_Places";
    } else if (location === "OUTER_SPACE") {
      folderName = "Outer_Space";
    } else {
      folderName = capitalizeFirstLetter(location);
    }
    fileName = capitalizeFirstLetter(celebrateWith);
    imageSrc = require(`../../assets/images/result_water/${folderName}/${fileName}.png`);
  }

  if (menu === MENU_NAME[1]) {
    let pagodaFolder = `${capitalizeFirstLetter(pagodaType)}_Pagoda`;
    if (pagodaType === "SAND_CASTLE") {
      pagodaFolder = "Sand_Pagoda";
    }

    if (flag === "ACN") {
      folderName = "ACN";
    }
    if (flag === "CARP") {
      folderName = "Carp";
    }
    if (flag === "ORANGE") {
      folderName = "Color";
    }
    if (flag === "PRIDE") {
      folderName = "Rainbow";
    }
    if (flag === "PEACE") {
      folderName = "White";
    }

    if (flower === "LOTUS") {
      fileName = "lotus";
    } else {
      fileName = capitalizeFirstLetter(flower);
    }

    imageSrc = require(`../../assets/images/result_pagodas/${pagodaFolder}/${folderName}/${fileName}.png`);
  }
  return (
    <div id="image-card" className="share-img-container">
      <Row>
        <div className="img-box">
          <img className="acn-img" src={logo} />
          <Image
            src={imageSrc}
            alt="Card-image"
            className="img-border-radius"
            preview={false}
          />
        </div>
      </Row>

      <Row className="blassing-box">
        <Col xs={24} lg={18} className="wrapper-title-result">
          <p
            className="p-32-700 title-result"
            style={{ color: "#A100FF", margin: "0" }}
          >
            {titleWord}
          </p>
          <div className="style-blessing">{blessingWord}</div>
          <div className={language === "en" ? "displayShow" : "displayNone"}>
            <div id="gen-ai">
              <img src={GenAIIcon} />
              Generated by Generative AI
            </div>
          </div>
        </Col>
        <Col
          xs={24}
          lg={6}
          style={{
            textAlign:
             window?.innerWidth <= 991
                ? "center"
                : "right",
          }}
          className="wrapper-title-result"
        >
          <img src={qrcode} />
          <div>
            <a href="https://esongkran.acnthcares.com">
              esongkran.acnthcares.com
            </a>
          </div>
        </Col>
      </Row>

      <Row>
        <Col
          style={{
            marginTop: "24px",
          }}
          className="sustainable-information"
        >
          <strong>{expTtitle}:</strong> {expDescript}
        </Col>
      </Row>
    </div>
  );
};

export default ShareImage;
