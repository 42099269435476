import React  from 'react';
export default function Avatar5({ custom }) {
    const face = custom.face;
    const neck = custom.neck;
    const hair = custom.hairType1;
    const hair4 = custom.hairType4;
    return (
        <svg width="99" height="179" viewBox="0 0 99 179" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="21.1394" y="101.837" width="59.0885" height="75.3493" rx="25.6508" fill={neck} />
            <path d="M20.4522 241.542C20.4522 228.488 19.9013 208.104 21.3683 194.592C22.8355 181.079 22.514 180.384 22.5136 158.406C24.3882 148.834 22.5136 141.304 22.5136 131.61L18.1836 112.774C23.7259 109.839 35.0732 101.145 34.3731 108.189C33.498 116.994 37.1711 120.617 49.3093 129.549C63.738 120.388 63.5089 106.646 70.3795 106.646C84.3681 106.646 74.9603 157.49 77.2505 183.37C79.5407 209.249 81.6021 239.252 81.6021 239.252C71.7541 247.726 32.3399 249.331 20.4522 241.542Z" fill="#C570A8" />
            <path d="M29.3345 163.034L30.3504 159.275L30.5815 159.337C33.9434 160.219 39.4141 161.102 45.5454 161.619C53.5722 162.296 62.1613 162.327 70.8704 161.549L71.3455 161.506L71.7045 165.383C62.7557 166.212 53.9271 166.207 45.6604 165.536L45.2181 165.499C38.7986 164.958 33.0478 164.023 29.4574 163.067L29.3345 163.034Z" fill="#EBC2EE" />
            <path fillRule="evenodd" clipRule="evenodd" d="M38.9665 168.851C36.4485 168.397 35.0489 166.082 36.3578 163.746L36.4024 163.668C37.3656 162.022 39.018 161.451 40.9399 161.773C42.2949 162.001 43.7587 162.675 44.906 163.484C45.351 163.798 45.5132 164.382 45.2939 164.881C43.9879 167.851 41.4396 169.296 38.9665 168.851ZM42.6607 164.79C42.0083 164.444 41.3037 164.173 40.6662 164.051L40.5606 164.032C39.4835 163.851 38.7851 164.099 38.3554 164.866C37.8798 165.714 38.2959 166.403 39.3723 166.597C40.5399 166.807 41.7932 166.244 42.6941 164.887L42.7323 164.828L42.6607 164.79Z" fill="#873589" />
            <path fillRule="evenodd" clipRule="evenodd" d="M44.6224 163.584C45.8017 162.457 47.3701 161.43 48.8818 160.938C51.0305 160.238 52.9992 160.622 54.3556 162.373C56.1993 164.753 55.0339 167.545 52.295 168.473C49.5744 169.395 46.4413 168.188 44.4451 165.023C44.1546 164.562 44.2287 163.961 44.6224 163.584ZM51.5602 166.304C52.9117 165.846 53.3449 164.808 52.545 163.776L52.5037 163.724C51.8109 162.869 50.8701 162.699 49.5906 163.115C48.7283 163.396 47.7889 163.94 46.9727 164.577L46.953 164.593L46.9732 164.618C48.3304 166.248 50.0352 166.787 51.4941 166.325L51.5602 166.304Z" fill="#873589" />
            <path d="M43.8286 164.632C44.1216 164.071 44.8135 163.854 45.374 164.147C45.9344 164.441 46.1512 165.132 45.8582 165.693C44.5729 168.151 43.6221 171.71 44.0262 174.135C44.1302 174.759 43.7087 175.349 43.0849 175.453C42.461 175.557 41.8711 175.135 41.7671 174.512C41.2643 171.494 42.3339 167.491 43.8286 164.632Z" fill="#873589" />
            <path d="M44.1127 164.505C44.5391 164.038 45.2634 164.005 45.7305 164.431C47.2282 165.799 47.8658 169.079 47.9807 173.81L47.9842 173.964C48.0592 177.407 47.8071 181.488 47.462 183.558C47.358 184.182 46.768 184.603 46.1442 184.499C45.5204 184.395 45.0989 183.805 45.2029 183.182L45.2323 182.999C45.533 181.05 45.7571 177.339 45.6976 174.166L45.6945 174.013C45.6055 169.928 45.0489 166.964 44.2139 166.149L44.1863 166.123C43.7192 165.696 43.6863 164.972 44.1127 164.505Z" fill="#873589" />
            <path d="M61.228 104.61L65.7902 105.019C65.2896 110.596 64.2379 114.936 62.1556 118.751C59.618 123.401 55.6549 126.907 49.9902 129.201L49.8127 129.272L49.0146 129.59L48.2045 129.304C41.0839 126.795 36.7223 122.6 34.2053 116.872C33.572 115.431 33.0696 113.935 32.6141 112.234L32.5455 111.975C32.4208 111.499 32.3002 111.011 32.1672 110.448L31.6596 108.265L31.6052 108.038C31.427 107.302 31.2789 106.757 31.1277 106.294L31.105 106.226L35.4504 104.777C35.6557 105.393 35.8387 106.059 36.0511 106.936L36.2116 107.614C36.3404 108.168 36.5291 108.986 36.5537 109.092L36.6906 109.671C36.8118 110.175 36.9226 110.616 37.0387 111.049C37.4382 112.541 37.8689 113.823 38.3987 115.029C40.2977 119.35 43.4293 122.511 48.7008 124.6L48.9157 124.684L48.9265 124.68C53.2029 122.814 56.1315 120.161 58.0638 116.686L58.1349 116.557C59.8131 113.482 60.7234 109.841 61.1865 105.055L61.228 104.61Z" fill="#EBC2EE" />
            <rect x="39.6904" y="93.792" width="17.9101" height="26.8804" rx="8.95507" fill={neck} />
            <path d="M56.4112 104.236C56.7302 103.479 57.6032 103.123 58.3609 103.442C59.1186 103.761 59.4742 104.634 59.1552 105.392C57.9507 108.252 53.2023 110.082 48.0495 110.082C43.3885 110.082 39.4275 107.985 38.5102 105.294C38.2449 104.516 38.6607 103.67 39.4389 103.405C40.2006 103.145 41.0271 103.538 41.3106 104.285L41.3283 104.334C41.4905 104.81 42.1535 105.475 43.1777 106.018C44.4732 106.703 46.1499 107.104 48.0495 107.104C52.0924 107.104 55.7247 105.72 56.3919 104.28L56.4112 104.236Z" fill="#37383A" />
            <rect x="46.332" y="106.875" width="4.58049" height="3.43538" rx="1.71769" fill="#FFDD64" />
            <path d="M97.7047 166.566C96.851 160.163 94.2654 141.799 93.0091 138.392C90.8532 132.545 84.5413 137.347 83.1909 134.55V174.4C87.1607 172.873 99.9034 175.36 97.7047 166.566Z" fill="#F2F7FA" />
            <path d="M0.62551 165.934C1.46463 159.486 4.00588 140.995 5.24069 137.565C7.35965 131.677 13.5633 136.513 14.8906 133.696V173.823C10.9889 172.285 -1.53545 174.79 0.62551 165.934Z" fill="#F2F7FA" />
            <path d="M92.5951 135.503C90.3964 126.709 85.5715 115.655 83.4341 111.227V151.077C87.4038 149.55 94.7937 144.298 92.5951 135.503Z" fill="#E3E9EC" />
            <path d="M55.6074 125.884C58.0122 121.533 63.2773 112.545 63.2773 103.898C63.2773 103.898 63.3414 104.172 73.6103 106.174C83.8795 108.175 83.663 112.372 83.663 112.372C85.7336 115.816 83.0782 134.407 83.6628 154.115C84.2474 173.823 83.6628 176.499 83.6628 185.431C78.7414 187.011 73.0059 190.177 60.7592 191.386C60.7592 189.092 59.8166 185.703 59.7291 183.111C58.298 174.648 57.7832 164.017 57.0962 154.115C56.6163 147.2 55.6074 134.015 55.6074 125.884Z" fill="#EBF4F9" />
            <path d="M55.4927 126.342L60.5313 101.035L65.4553 102.524L68.8906 105.157C66.3714 112.372 65.7988 118.899 67.0585 126.113C63.5086 123.594 57.4012 125.35 55.4927 126.342Z" fill="#F7FAFF" />
            <path d="M5.79179 135.503C7.99042 126.709 12.8152 115.655 14.9528 111.227V151.077C10.983 149.55 3.59316 144.298 5.79179 135.503Z" fill="#E3E9EC" />
            <path d="M42.7792 125.884C40.3746 121.533 35.1093 112.545 35.1093 103.898C35.1093 103.898 35.0452 104.172 24.7763 106.174C14.5073 108.175 14.7238 112.372 14.7238 112.372C12.6532 115.816 15.3084 134.407 14.7238 154.115C14.1392 173.823 14.7238 176.499 14.7238 185.431C19.6454 187.011 25.3807 190.177 37.6276 191.386C37.6276 189.092 38.5702 185.703 38.6577 183.111C40.0886 174.648 40.6036 164.017 41.2907 154.115C41.7703 147.2 42.7792 134.015 42.7792 125.884Z" fill="#EBF4F9" />
            <path d="M42.8943 126.342L37.8559 101.035L32.9318 102.524L29.4963 105.157C32.0158 112.372 32.5882 118.899 31.3285 126.113C34.8784 123.594 40.9857 125.35 42.8943 126.342Z" fill="#F7FAFF" />
            <path d="M17.0363 75.7863C24.5099 75.7863 30.5684 70.0363 30.5684 62.9434C30.5684 55.8504 24.5099 50.1005 17.0363 50.1005C9.56269 50.1005 3.50415 55.8504 3.50415 62.9434C3.50415 70.0363 9.56269 75.7863 17.0363 75.7863Z" fill={neck} />
            <path d="M78.4949 75.6409C85.3319 75.6409 90.8743 69.9073 90.8743 62.8346C90.8743 55.7619 85.3319 50.0283 78.4949 50.0283C71.6579 50.0283 66.1155 55.7619 66.1155 62.8346C66.1155 69.9073 71.6579 75.6409 78.4949 75.6409Z" fill={neck} />
            <path d="M14.2683 38.5338C14.2683 19.8646 28.9825 4.7302 47.1333 4.7302C65.2844 4.7302 79.9984 19.8646 79.9984 38.5338V72.1557C77.3481 93.9644 61.4457 105.959 47.1333 105.959C32.8212 105.959 16.2119 91.42 14.2683 72.1557V38.5338Z" fill={face} />
            <path fillRule="evenodd" clipRule="evenodd" d="M38.3164 80.1919V83.2876C38.3164 88.0426 42.1711 91.8973 46.9261 91.8973C51.6811 91.8973 55.5358 88.0426 55.5358 83.2876V80.1919H38.3164Z" fill="#393939" />
            <mask id="mask0_438_5" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="38" y="79" width="18" height="13">
                <path fillRule="evenodd" clipRule="evenodd" d="M38.3302 79.6214C38.3208 79.7869 38.3159 79.9533 38.3159 80.1209V83.2872C38.3159 88.0422 42.1706 91.8969 46.9256 91.8969C51.6806 91.8969 55.5353 88.0422 55.5353 83.2872V80.1209C55.5353 79.9533 55.5306 79.7869 55.521 79.6214H38.3302Z" fill="white" />
            </mask>
            <g mask="url(#mask0_438_5)">
                <path d="M46.9255 94.6589C49.793 94.6589 52.1176 93.3929 52.1176 91.8313C52.1176 90.2696 49.793 89.0036 46.9255 89.0036C44.058 89.0036 41.7334 90.2696 41.7334 91.8313C41.7334 93.3929 44.058 94.6589 46.9255 94.6589Z" fill="#FF6C6C" />
                <rect x="38.3159" y="80.1917" width="17.2194" height="3.55106" fill="white" />
            </g>
            <path d="M18.9272 43.1236C20.7593 36.5277 26.714 28.1607 29.9203 24.8016C33.1266 21.4425 42.133 18.2809 44.7284 17.6702C44.7284 15.5326 51.6018 -0.769969 33.673 3.49876C26.0981 5.30229 18.044 8.41518 9.34105 23.9888C0.638103 39.5626 15.6426 85.3471 16.1006 70.6896C16.5586 56.0319 16.6368 51.3685 18.9272 43.1236Z" fill={hair4} />
            <path d="M24.487 29.9372C29.7782 22.1202 34.0145 19.5956 41.2612 14.3493C42.3021 15.2573 44.8271 17.6984 50.1108 20.8765C43.1059 23.0557 31.2812 29.6345 21.4677 48.7031C20.5619 42.0442 21.5164 34.3259 24.487 29.9372Z" fill={hair4} />
            <path d="M42.3133 1.43673C50.6374 -2.39315 70.8918 4.87829 80.6293 23.9888C80.6293 23.9888 83.6174 31.6726 85.7241 39.7711C86.854 42.1928 87.5257 49.0679 87.9221 55.7897C88.4926 65.4704 83.4909 74.5387 75.3034 79.736C75.3034 79.736 72.6698 57.6351 62.8215 45.4966C54.3269 35.0264 43.4466 26.4306 40.0286 21.914C39.2013 20.8208 38.3448 19.5391 38.1873 18.1771C37.7523 14.4186 39.9343 6.47842 42.3133 1.43673Z" fill={hair} />
            <path d="M51.4851 38.4879C47.9351 32.1898 42.7821 25.1134 39.0032 20.533C40.5681 20.533 41.866 18.1965 46.4464 22.6854C52.1721 28.2964 52.2865 31.3883 51.4851 38.4879Z" fill={hair} />
            <path d="M38.307 47.5431C39.1284 47.5073 39.8233 48.144 39.8592 48.9654C39.8943 49.7697 39.2846 50.4527 38.488 50.5145L38.4369 50.5176L25.3154 51.0909C24.494 51.1268 23.7991 50.49 23.7632 49.6687C23.728 48.8644 24.3378 48.1813 25.1343 48.1195L25.1854 48.1164L38.307 47.5431Z" fill={hair} />
            <path d="M34.6081 56.5359C35.2328 56.4372 35.8193 56.8636 35.918 57.4882C36.0143 58.0973 35.6114 58.67 35.0122 58.7898L34.9657 58.7981L28.7965 59.7733C28.1718 59.8721 27.5854 59.4457 27.4866 58.821C27.3903 58.212 27.7932 57.6392 28.3924 57.5195L28.4389 57.5111L34.6081 56.5359Z" fill="black" />
            <path d="M57.138 56.8908C57.2343 56.2818 57.7942 55.8612 58.4011 55.9321L58.4479 55.9386L64.6169 56.914C65.2416 57.0128 65.6679 57.5992 65.5692 58.2239C65.4729 58.833 64.9129 59.2535 64.306 59.1826L64.2592 59.1761L58.0902 58.2007C57.4656 58.102 57.0392 57.5155 57.138 56.8908Z" fill="black" />
            <path d="M33.6189 54.9189C35.1147 54.9189 36.3315 56.1139 36.3664 57.6014L36.3672 57.6672V64.2581C36.3672 65.776 35.1367 67.0064 33.6189 67.0064C32.1231 67.0064 30.9063 65.8114 30.8714 64.3239L30.8706 64.2581V57.6672C30.8706 56.1493 32.1011 54.9189 33.6189 54.9189Z" fill="black" />
            <path d="M59.6881 54.3213C61.1839 54.3213 62.4006 55.5164 62.4355 57.0039L62.4363 57.0697L62.436 63.6606C62.436 65.1785 61.2055 66.4089 59.6877 66.4089C58.1918 66.4088 56.9751 65.2137 56.9402 63.7263L56.9395 63.6605L56.9397 57.0695C56.9397 55.5516 58.1702 54.3212 59.6881 54.3213Z" fill="black" />
            <path d="M53.2863 48.5134C53.3355 47.7099 54.0133 47.0944 54.812 47.1162L54.8631 47.1185L67.9727 47.9208C68.7933 47.971 69.4179 48.677 69.3676 49.4976C69.3185 50.3011 68.6406 50.9167 67.842 50.8948L67.7908 50.8925L54.6812 50.0902C53.8606 50.04 53.2361 49.334 53.2863 48.5134Z" fill={hair} />
            <path d="M44.0474 64.0713L47.9047 64.6003L47.8591 64.9128C47.7805 65.4142 47.6795 65.8641 47.4139 66.9398L47.1543 67.9883C47.117 68.1408 47.0843 68.2771 47.0531 68.4099L47.0377 68.4761C46.6751 70.0336 46.5697 71.0359 46.707 71.6085C46.7288 71.6994 46.7528 71.7598 46.7745 71.7952C46.7818 71.8072 46.784 71.8095 46.7907 71.8144C46.8179 71.8341 46.8694 71.8581 46.9592 71.8823C47.0933 71.9185 47.2703 71.9416 47.4924 71.9459L47.5681 71.9466V75.8401C45.1004 75.8401 43.4266 74.6251 42.9209 72.5165C42.6122 71.2292 42.7386 69.8445 43.1831 67.8668L43.2456 67.5934C43.2846 67.4258 43.3246 67.2585 43.3707 67.0699L43.7407 65.5705C43.9014 64.9071 43.9698 64.5839 44.0208 64.254L44.0474 64.0713Z" fill={neck} />
            <path d="M83.0957 17.6861C77.3543 18.331 76.2265 16.7904 72.9457 14.7842V19.9431L75.2013 24.6721C78.8922 24.6721 81.4553 23.7048 83.0957 17.6861Z" fill={hair} />
            <path d="M87.6067 23.49C81.8653 24.1348 80.7375 22.5942 77.4567 20.5881V25.7471L79.7123 30.4759C83.4032 30.4759 85.9663 29.5086 87.6067 23.49Z" fill={hair} />
            <path d="M91.6331 31.5864C85.8917 32.2312 84.7639 30.6906 81.4831 28.6845V33.8435L83.7386 38.5723C87.4295 38.5723 89.9927 37.605 91.6331 31.5864Z" fill={hair} />
        </svg>
    );
}