import axios from "axios";
let response;

// axios.defaults.timeout = 10

async function getWaterFestivalBlessingWord(language, request_id, session_id, params) {
    await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/api/v1/songkran/water-festival/blessing-word`,
        // url: `/api/v1/songkran/water-festival/blessing-word`,
        

        headers: {
            "Content-Language": language,
            "request_id": request_id,
            "session_id": session_id
        },
        data: params
    })
        .then(res => response = res.data)
        .catch(err => response = err);
    return response;
}

async function postSandPagodaRandom(language, request_id, session_id, params) {
    await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/api/v1/songkran/sand-pagodas`,
        headers: {
            "Content-Language": language,
            "request_id": request_id,
            "session_id": session_id
        },
        data: params
    })
        .then(res => response = res.data)
        .catch(err => response = err);
    return response;
}

async function getSandPagodaRandom(language, request_id, session_id, includeThisSession) {
    await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/api/v1/songkran/sand-pagodas?includeThisSession=${includeThisSession}`,
        headers: {
            "Content-Language": language,
            "request_id": request_id,
            "session_id": session_id
        }
    })
        .then(res => response = res.data)
        .catch(err => response = err);
    return response;
}

async function uploadBlob(request_id, session_id, params) {
    await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/api/v1/songkran/sand-pagodas/uploadfile`,
        headers: {
            "request_id": request_id,
            "session_id": session_id
        },
        data: params
    })
        .then(res => response = res.data)
        .catch(err => response = err);
    return response;
}



export { getWaterFestivalBlessingWord, postSandPagodaRandom, getSandPagodaRandom, uploadBlob }