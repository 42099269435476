import React, { useState } from "react";
import ContentCenter from "../../components/content-center/ContentCenter";
import { Col, Modal, Grid, Row } from "antd";
import MainBackground from "../../components/main-background/MainBackground";
import { useMain } from "../../context/MainContext";
import CustomButton from "../../components/button/CustomButton";
import CardDetails from "../../components/card-details/CardDetails";
import lotusFlower from "../../assets/images/flower/flower-lotus.svg";
import lotusWhiteFlower from "../../assets/images/flower/flower-lotus-white-bg.svg";
import goldenShower from "../../assets/images/flower/flower-golden-shower.svg";
import goldenWhiteShower from "../../assets/images/flower/flower-golden-shower-white-bg.svg";
import marigoldFlower from "../../assets/images/flower/flower-marigold.svg";
import marigoldWhiteFlower from "../../assets/images/flower/flower-marigold-white-bg.svg";
import orchidFlower from "../../assets/images/flower/flower-orchid.svg";
import orchidWhiteFlower from "../../assets/images/flower/flower-orchid-white-bg.svg";
import { usePagodaContext } from "../../context/PagodaContext";
import AnimateClickable from "../../components/avatar/AnimateClickable";
import HeaderTitle from "../../components/header-title/HeaderTitle";
import ReactGA from "react-ga4";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "./style.css";

const FlowersDetails = [
  {
    flowerName: "LOTUS",
    flowerImage: lotusFlower,
    flowerWhiteImage: lotusWhiteFlower,
    flowerTitle: {
      en: "Lotus",
      th: "ดอกบัว",
    },
    flowerDetails: {
      en: "A symbol of purity. This flower emerges from muddy water reminding us that goodness can be found anywhere.",
      th: "สัญลักษณ์แห่งความบริสุทธิ์ ยังผลิบานจากโคลนตมขึ้นมาได้ ทุกสถานที่ล้วนมีความดีงาม ซุกซ่อนอยู่ทั้งนั้น",
    },
  },
  {
    flowerName: "GOLDEN_SHOWER",
    flowerImage: goldenShower,
    flowerWhiteImage: goldenWhiteShower,
    flowerTitle: {
      en: "Golden Shower",
      th: "ดอกคูณ",
    },
    flowerDetails: {
      en: "Golden Showers live up to their name, believed to bring wealth. The more, the merrier!",
      th: "ทรัพย์สินของคุณจะเพิ่มพูน เพราะดอกคูณพร้อม ‘คูณ’ ให้ ทุกอย่างเพิ่มขึ้นอีกหลายเท่าตัว",
    },
  },
  {
    flowerName: "ORCHID",
    flowerImage: orchidFlower,
    flowerWhiteImage: orchidWhiteFlower,
    flowerTitle: {
      en: "Orchid",
      th: "ดอกกล้วยไม้",
    },
    flowerDetails: {
      en: "Orchids symbolize love and reflect the diversity of love through their wide array of species.",
      th: "ตัวแทนแห่งความรัก เพราะความรักมีได้หลากหลาย ไม่แพ้สายพันธุ์ของดอกกล้วยไม้",
    },
  },
  {
    flowerName: "MARIGOLD",
    flowerImage: marigoldFlower,
    flowerWhiteImage: marigoldWhiteFlower,
    flowerTitle: {
      en: "Marigold",
      th: "ดอกดาวเรือง",
    },
    flowerDetails: {
      en: "The vibrant blooms of marigolds are a symbol of abundance and prosperity.",
      th: "ดอกดาวเรืองยามเบ่งบาน สื่อถึงความรุ่งเรืองและ การเติบโตในหน้าที่การงาน",
    },
  },
];

const PagodaFlower = () => {
  const { language, translations, goNextPage } = useMain();
  const { pagodaPayload } = usePagodaContext();
  const { useBreakpoint } = Grid;
  const { xs, sm, md, lg, xl } = useBreakpoint();

  const [selectedFlower, setSelectedFlower] = useState(FlowersDetails[0]);
  const [pickFlower, setPickFlower] = useState("LOTUS");

  const onSelectFlower = (flower) => {
    ReactGA.event({
      category: "user action",
      action: "select flower",
      label: flower,
    });
    setSelectedFlower(flower);
    setPickFlower(flower.flowerName);
  };

  const handleClick = () => {
    pagodaPayload.flower = selectedFlower.flowerName;
    goNextPage();
  };

  return (
    <MainBackground className="bg-container">
      <Modal
        open={true}
        closable={false}
        width={1000}
        footer={null}
        className="flower-select-modal"
        style={{ margin: "5% auto 0 auto", top: "auto" }}
      >
        <ContentCenter paddingLeft={0} paddingRight={0}>
          <Col xs={24} align="middle">
            <HeaderTitle
              title={translations[language].pagoda.flower_page.title}
            />
          </Col>
          <Row gutter={[16, 24]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }} className="hide-767">
              <Row gutter={[16, 16]}>
                {FlowersDetails?.map((flower, index) => (
                  <Col xs={12} lg={12} key={index} align={xs ? "middle" : ""}>
                    <div
                      onClick={() => onSelectFlower(flower)}
                      className="bouncein"
                      style={{
                        "--delay": `${index * 100}ms`,
                        display: !xs && sm && md && !lg && !xl && "flex",
                        justifyContent:
                          !xs && sm && md && !lg && !xl && "center",
                      }}
                    >
                      <AnimateClickable
                        className={`flower-big-icon ${
                          pickFlower === flower?.flowerName
                            ? "flower-big-icon-select"
                            : ""
                        }`}
                      >
                        <img
                          style={{ maxWidth: "156px" }}
                          src={flower?.flowerImage}
                          alt={flower?.flowerName}
                        />
                      </AnimateClickable>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>

            <Col
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              style={{ overflowX: "scroll" }}
              className="show-767"
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                }}
              >
                {FlowersDetails?.map((flower, index) => (
                  <Col xs={12} lg={7} key={index} align={xs ? "middle" : ""}>
                    <div
                      onClick={() => onSelectFlower(flower)}
                      key={index}
                      className="bouncein"
                      style={{
                        "--delay": `${index * 100}ms`,
                      }}
                    >
                      <AnimateClickable className={`flower-icon`}>
                        <img
                          className={`flower-img ${
                            pickFlower === flower?.flowerName
                              ? "flower-icon-select"
                              : ""
                          }`}
                          src={flower?.flowerImage}
                          alt={flower?.flowerName}
                        />
                      </AnimateClickable>
                    </div>
                  </Col>
                ))}
              </div>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              align="center"
              style={{
                marginTop: sm && md && lg ? null : "40px",
                marginBottom: xs && "100px",
                paddingLeft: xs && "16px",
                paddingRight: xs && "16px",
              }}
            >
              <CardDetails
                imageWidth={174}
                imageHeight={174}
                imageSrc={
                  selectedFlower?.flowerWhiteImage ??
                  FlowersDetails[0].flowerWhiteImage
                }
                altImage={"Flower Image"}
                title={
                  selectedFlower?.flowerTitle[language] ??
                  FlowersDetails[0].flowerTitle[language]
                }
                details={
                  selectedFlower?.flowerDetails[language] ??
                  FlowersDetails[0].flowerDetails[language]
                }
              />
            </Col>
          </Row>

          <Row
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Col
              align="middle"
              className={xs && "bottom-button"}
              style={{
                marginTop: !xs && "128px",
                width: xs ? "100%" : "558px",
                paddingLeft: xs && "16px",
                paddingRight: xs && "16px",
              }}
            >
              <CustomButton
                style="pagoda-next-style"
                isCloseHover={xs}
                height={xs ? 48 : null}
                text={translations[language].pagoda.flower_page.next}
                onClick={() => handleClick()}
                size="large"
              />
            </Col>
          </Row>
        </ContentCenter>
      </Modal>
    </MainBackground>
  );
};

export default PagodaFlower;
