import React, {
  useEffect,
  useState,
  // useRef,
  useMemo,
  useCallback,
} from "react";
import flag from "../../assets/images/pagoda/flag-group.svg";
import logo from "../../assets/images/pagoda/acn-logo.svg";
import { getSandPagodaRandom } from "../../services/modal/api";
import { useMain } from "../../context/MainContext";
import { PagodaAnimation } from "./style";
import PagodaParade from "../../components/pagoda/PagodaParade";
import ButtonGetstart from "../../components/button/ButtonGetStart";
import Copyright from "../../components/copyright/Copyright";
import ReactGA from "react-ga4";
import "./style.css";
import { Grid } from "antd";

const Parade = ({ cardRef }) => {
  const {
    language,
    translations,
    requestId,
    sessionId,
    goHomePage,
    menu,
    MENU_NAME,
    currentPage,
    PAGE_STATE
  } = useMain();
  const templeAnimate = "silhouette1-animation";
  const templeAnimate2 = "silhouette2-animation";
  // const initialized = useRef(false);
  const [windowwidth, setWindowWidth] = useState(window.innerWidth);
  const [kratongs, setKratongs] = useState({ 1: [], 2: [], 3: [] });
  const includeThisSession = useMemo(() =>
    menu === MENU_NAME[1] ? true : false, [menu]
  );
  const screens = Grid.useBreakpoint();

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  useEffect(() => {
    let timeout;
    timeout = setTimeout(() => {
      setKratongs((prev) => {
        return { ...prev, 2: [] };
      });
    }, (windowwidth / 20) * 1000);
    return () => {
      clearInterval(timeout);
    };
  }, [kratongs, windowwidth]);

  const formatLine = { 1: [], 2: [], 3: [] }
  const [pagodas, setPagodas] = useState([]);
  const [pagodasLine, setPagodasLine] = useState(formatLine);

  const createPagoda = useCallback(async (isInclude) => {
    try {
      const res = await getSandPagodaRandom(
        language,
        requestId,
        sessionId,
        isInclude
      );
      setPagodas(res.sandPagodas);
    } catch (e) {
      return e;
    }
  }, [language,
    requestId,
    sessionId]);

  const setLine = useCallback(
    (i, data) => {
      setPagodasLine((prev) => {
        const lineNumber = (i % 3) + 1;
        const newState = JSON.parse(JSON.stringify({ ...prev }));
        Array.isArray(newState[lineNumber])
          ? newState[lineNumber].push(data[i])
          : (newState[lineNumber] = []);
        return JSON.parse(JSON.stringify({ ...newState }));
      });
    },
    [pagodasLine]
  );

  useEffect(() => {
    let i = 0;
    let interval;
    if (pagodas?.length > 0) {
      if (pagodasLine?.["1"]?.length === 0) {
        setLine(i, pagodas);
        i = 1;
      }
      interval = setInterval(() => {
        setLine(i, pagodas);
        if (i === 29) {
          i = 0;
          clearInterval(interval);
          createPagoda(false);
        } else i++;
      }, 5000);
    }

    if (currentPage) {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [pagodas, currentPage]);


  useEffect(() => {
    let interval;
    if (!currentPage)
      interval = setInterval(() => {
        for (let i = 1; i < 4; i++) {
          const wave = document?.getElementById(`wave${i}`);
          wave?.childNodes?.forEach((item) => {
            if (item?.getBoundingClientRect()?.x > window?.innerWidth && item?.parentNode?.isEqualNode(wave))
              wave?.removeChild(item);
          })
        }
      }, 10000);

    return () => {
      clearInterval(interval);
    };

  }, [currentPage])

  const handleParade = (items) => {
    return items?.map((pagoda, index) => {
      return (
        <PagodaAnimation key={index} isMobile={screens.xs}>
          <PagodaParade pagoda={pagoda} />
        </PagodaAnimation>
      );
    });
  };

  const handleClick = () => {
    ReactGA.event({
      category: "user action",
      action: "pagoda parade: click play again",
    });
    goHomePage();
  };

  useEffect(() => {
    if (!currentPage) {
      createPagoda(includeThisSession);
      setPagodasLine(formatLine);
    }
    return () => {
      if (!currentPage) {
        setPagodas([]);
      }
    }
  }, [currentPage, createPagoda, includeThisSession]);

  useEffect(() => {
    if (currentPage === PAGE_STATE[12]) {
      setPagodasLine(formatLine)
    }
  }, [currentPage])

  const sand1 = useMemo(() => {
    return handleParade(pagodasLine?.["1"]);
  }, [pagodasLine?.["1"]]);

  const sand2 = useMemo(() => {
    return handleParade(pagodasLine?.["2"]);
  }, [pagodasLine?.["2"]]);

  const sand3 = useMemo(() => {
    return handleParade(pagodasLine?.["3"]);
  }, [pagodasLine?.["3"]]);

  return (
    <div ref={cardRef}>
      <section className="view-container">
        <section className="city-section">
          <div className="top-left">
            <img src={logo} className="logo" />
          </div>
          <div className="flag-container">
            <img src={flag} />
          </div>
          <div className="silhouette-container">
            <div
              className={`view-background-setting-silhouette2 silhouette2 ${templeAnimate2}`}
            ></div>
          </div>
          <div className="silhouette-container">
            <div
              className={`view-background-setting-silhouette1 silhouette1 ${templeAnimate}`}
            ></div>
          </div>

          <div className="pagoda-container">
            <div
              className="view-background-setting-pagoda"
              style={{ zIndex: 2 }}
            ></div>
          </div>
        </section>
      </section>

      <section className="wave-container">
        <section className="wave-wrapper">
          <div className={`wave-image position4`}>
            <div className="wave-background-setting wave4"></div>
          </div>
          <div className={`wave-image position3`}>
            <div id="wave3" className="wave-background-setting wave3">
              {sand1}
            </div>
          </div>
          <div className={`wave-image position2`}>
            <div id="wave2" className="wave-background-setting wave2">
              {sand2}
            </div>
          </div>
          <div className="wave-image position1">
            <div id="wave1" className="wave-background-setting wave1">
              {sand3}
            </div>
          </div>
          {currentPage === PAGE_STATE[12] && <div className="button-box">
            <ButtonGetstart
              message={translations[language].pagoda.parade.button}
              className="parade-button-style"
              onClick={() => handleClick()}
              size="large"
            />
          </div>}
          <Copyright />
        </section>
      </section>
    </div>
  );
};

export default Parade;
