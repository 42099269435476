import React from "react";
import { Flex, Modal, Col, Row } from "antd";
import AnswerCard from "../../components/card/AnswerCard";
import ReactGA from "react-ga4";
import FriendsImage from "../../assets/images/person/Friends.svg";
import FamilyImage from "../../assets/images/person/Family.svg";
import PartnerImage from "../../assets/images/person/Partner.svg";
import PetImage from "../../assets/images/person/Pet.svg";
import MainBackground from "../../components/main-background/MainBackground";
import ContentCenter from "../../components/content-center/ContentCenter";
import { useMain } from "../../context/MainContext";
import { usePagodaContext } from "../../context/PagodaContext";
import HeaderTitle from "../../components/header-title/HeaderTitle";
import "./style.css";

const QuestionPerson = () => {
  const { language, translations, goNextPage } = useMain();
  const { pagodaPayload } = usePagodaContext();

  const handleClick = (value) => {
    pagodaPayload.celebrateWith = value;
    ReactGA.event({
      category: "user action",
      action: "select celebrate with",
      label: value
    });
    goNextPage();
  };

  return (
    <MainBackground className="bg-container">
      <Modal open={true} closable={false} width={1400} footer={null}>
        <ContentCenter>
          <Col xs={24} align="middle">
            <HeaderTitle title={translations[language].question.person.title} />
          </Col>
          <Flex justify="center" wrap="wrap" gap="large">
            <Row gutter={[16, 16]}>
              <Col xs={12} lg={6}>
                <AnswerCard
                  image={FriendsImage}
                  title={translations[language].question.person.a1.h1}
                  description={translations[language].question.person.a1.d1}
                  onClick={() => handleClick("FRIEND")}
                />
              </Col>
              <Col xs={12} lg={6}>
                <AnswerCard
                  image={FamilyImage}
                  title={translations[language].question.person.a2.h1}
                  description={translations[language].question.person.a2.d1}
                  onClick={() => handleClick("PARENT")}
                />
              </Col>
              <Col xs={12} lg={6}>
                <AnswerCard
                  image={PartnerImage}
                  title={translations[language].question.person.a3.h1}
                  description={translations[language].question.person.a3.d1}
                  onClick={() => handleClick("PARTNER")}
                />
              </Col>
              <Col xs={12} lg={6}>
                <AnswerCard
                  image={PetImage}
                  title={translations[language].question.person.a4.h1}
                  description={translations[language].question.person.a4.d1}
                  onClick={() => handleClick("PET")}
                />
              </Col>
            </Row>
          </Flex>
        </ContentCenter>
      </Modal>
    </MainBackground>
  );
};

export default QuestionPerson;
