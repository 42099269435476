const pagodaImgSrc = {
  ICE: {
    ACN: {
      GOLDEN_SHOWER: require("../../assets/images/pagodas/Ice_Pagoda/ACN/Golden_shower.png"),
      LOTUS: require("../../assets/images/pagodas/Ice_Pagoda/ACN/Lotus.png"),
      MARIGOLD: require("../../assets/images/pagodas/Ice_Pagoda/ACN/Marigold.png"),
      ORCHID: require("../../assets/images/pagodas/Ice_Pagoda/ACN/Orchid.png"),
    },
    CARP: {
      GOLDEN_SHOWER: require("../../assets/images/pagodas/Ice_Pagoda/Carp/Golden_shower.png"),
      LOTUS: require("../../assets/images/pagodas/Ice_Pagoda/Carp/Lotus.png"),
      MARIGOLD: require("../../assets/images/pagodas/Ice_Pagoda/Carp/Marigold.png"),
      ORCHID: require("../../assets/images/pagodas/Ice_Pagoda/Carp/Orchid.png"),
    },
    PEACE: {
      GOLDEN_SHOWER: require("../../assets/images/pagodas/Ice_Pagoda/Peace/Golden_shower.png"),
      LOTUS: require("../../assets/images/pagodas/Ice_Pagoda/Peace/Lotus.png"),
      MARIGOLD: require("../../assets/images/pagodas/Ice_Pagoda/Peace/Marigold.png"),
      ORCHID: require("../../assets/images/pagodas/Ice_Pagoda/Peace/Orchid.png"),
    },
    PRIDE: {
      GOLDEN_SHOWER: require("../../assets/images/pagodas/Ice_Pagoda/Pride/Golden_shower.png"),
      LOTUS: require("../../assets/images/pagodas/Ice_Pagoda/Pride/Lotus.png"),
      MARIGOLD: require("../../assets/images/pagodas/Ice_Pagoda/Pride/Marigold.png"),
      ORCHID: require("../../assets/images/pagodas/Ice_Pagoda/Pride/Orchid.png"),
    },
    ORANGE: {
      GOLDEN_SHOWER: require("../../assets/images/pagodas/Ice_Pagoda/Orange/Golden_shower.png"),
      LOTUS: require("../../assets/images/pagodas/Ice_Pagoda/Orange/Lotus.png"),
      MARIGOLD: require("../../assets/images/pagodas/Ice_Pagoda/Orange/Marigold.png"),
      ORCHID: require("../../assets/images/pagodas/Ice_Pagoda/Orange/Orchid.png"),
    },
  },
  STONE: {
    ACN: {
      GOLDEN_SHOWER: require("../../assets/images/pagodas/Stone_Pagoda/ACN/Golden_shower.png"),
      LOTUS: require("../../assets/images/pagodas/Stone_Pagoda/ACN/Lotus.png"),
      MARIGOLD: require("../../assets/images/pagodas/Stone_Pagoda/ACN/Marigold.png"),
      ORCHID: require("../../assets/images/pagodas/Stone_Pagoda/ACN/Orchid.png"),
    },
    CARP: {
      GOLDEN_SHOWER: require("../../assets/images/pagodas/Stone_Pagoda/Carp/Golden_shower.png"),
      LOTUS: require("../../assets/images/pagodas/Stone_Pagoda/Carp/Lotus.png"),
      MARIGOLD: require("../../assets/images/pagodas/Stone_Pagoda/Carp/Marigold.png"),
      ORCHID: require("../../assets/images/pagodas/Stone_Pagoda/Carp/Orchid.png"),
    },
    PEACE: {
      GOLDEN_SHOWER: require("../../assets/images/pagodas/Stone_Pagoda/Peace/Golden_shower.png"),
      LOTUS: require("../../assets/images/pagodas/Stone_Pagoda/Peace/Lotus.png"),
      MARIGOLD: require("../../assets/images/pagodas/Stone_Pagoda/Peace/Marigold.png"),
      ORCHID: require("../../assets/images/pagodas/Stone_Pagoda/Peace/Orchid.png"),
    },
    PRIDE: {
      GOLDEN_SHOWER: require("../../assets/images/pagodas/Stone_Pagoda/Pride/Golden_shower.png"),
      LOTUS: require("../../assets/images/pagodas/Stone_Pagoda/Pride/Lotus.png"),
      MARIGOLD: require("../../assets/images/pagodas/Stone_Pagoda/Pride/Marigold.png"),
      ORCHID: require("../../assets/images/pagodas/Stone_Pagoda/Pride/Orchid.png"),
    },
    ORANGE: {
      GOLDEN_SHOWER: require("../../assets/images/pagodas/Stone_Pagoda/Orange/Golden_shower.png"),
      LOTUS: require("../../assets/images/pagodas/Stone_Pagoda/Orange/Lotus.png"),
      MARIGOLD: require("../../assets/images/pagodas/Stone_Pagoda/Orange/Marigold.png"),
      ORCHID: require("../../assets/images/pagodas/Stone_Pagoda/Orange/Orchid.png"),
    },
  },
  SAND_CASTLE: {
    ACN: {
      GOLDEN_SHOWER: require("../../assets/images/pagodas/Sand_Pagoda/ACN/Golden_shower.png"),
      LOTUS: require("../../assets/images/pagodas/Sand_Pagoda/ACN/Lotus.png"),
      MARIGOLD: require("../../assets/images/pagodas/Sand_Pagoda/ACN/Marigold.png"),
      ORCHID: require("../../assets/images/pagodas/Sand_Pagoda/ACN/Orchid.png"),
    },
    CARP: {
      GOLDEN_SHOWER: require("../../assets/images/pagodas/Sand_Pagoda/Carp/Golden_shower.png"),
      LOTUS: require("../../assets/images/pagodas/Sand_Pagoda/Carp/Lotus.png"),
      MARIGOLD: require("../../assets/images/pagodas/Sand_Pagoda/Carp/Marigold.png"),
      ORCHID: require("../../assets/images/pagodas/Sand_Pagoda/Carp/Orchid.png"),
    },
    PEACE: {
      GOLDEN_SHOWER: require("../../assets/images/pagodas/Sand_Pagoda/Peace/Golden_shower.png"),
      LOTUS: require("../../assets/images/pagodas/Sand_Pagoda/Peace/Lotus.png"),
      MARIGOLD: require("../../assets/images/pagodas/Sand_Pagoda/Peace/Marigold.png"),
      ORCHID: require("../../assets/images/pagodas/Sand_Pagoda/Peace/Orchid.png"),
    },
    PRIDE: {
      GOLDEN_SHOWER: require("../../assets/images/pagodas/Sand_Pagoda/Pride/Golden_shower.png"),
      LOTUS: require("../../assets/images/pagodas/Sand_Pagoda/Pride/Lotus.png"),
      MARIGOLD: require("../../assets/images/pagodas/Sand_Pagoda/Pride/Marigold.png"),
      ORCHID: require("../../assets/images/pagodas/Sand_Pagoda/Pride/Orchid.png"),
    },
    ORANGE: {
      GOLDEN_SHOWER: require("../../assets/images/pagodas/Sand_Pagoda/Orange/Golden_shower.png"),
      LOTUS: require("../../assets/images/pagodas/Sand_Pagoda/Orange/Lotus.png"),
      MARIGOLD: require("../../assets/images/pagodas/Sand_Pagoda/Orange/Marigold.png"),
      ORCHID: require("../../assets/images/pagodas/Sand_Pagoda/Orange/Orchid.png"),
    },
  },
};

const getPagodaImg = (type, flag, flower) => {
    switch (type){
        case "ICE": return pagodaImgSrc.ICE[flag][flower]
        case "STONE": return pagodaImgSrc.STONE[flag][flower]
        case "SAND_CASTLE": return pagodaImgSrc.SAND_CASTLE[flag][flower]
    }
};

export default getPagodaImg;
