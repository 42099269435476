import React from "react";
import { Flex, Modal, Col, Row } from "antd";
import AnswerCard from "../../components/card/AnswerCard";
import ReactGA from "react-ga4";
import KhaosoiImage from "../../assets/images/food/KhaoSoi.svg";
import TomyumImage from "../../assets/images/food/TomYum.svg";
import SomtumImage from "../../assets/images/food/SomTum.png";
import GangtaiplaImage from "../../assets/images/food/GaengTaiPla.svg";
import MainBackground from "../../components/main-background/MainBackground";
import ContentCenter from "../../components/content-center/ContentCenter";
import { useMain } from "../../context/MainContext";
import { usePagodaContext } from "../../context/PagodaContext";
import HeaderTitle from "../../components/header-title/HeaderTitle";
import "./style.css";

const QuestionFood = () => {
  const { language, translations, goNextPage } = useMain();
  const { pagodaPayload } = usePagodaContext();

  const handleClick = (value) => {
    pagodaPayload.food = value;
    ReactGA.event({
      category: "user action",
      action: "select food",
      label: value
    });
    goNextPage();
  };

  return (
    <MainBackground className="bg-container">
      <Modal open={true} closable={false} width={1400} footer={null}>
        <ContentCenter>
          <Col xs={24} align="middle">
            <HeaderTitle title={translations[language].question.food.title} />
          </Col>
          <Flex justify="center" wrap="wrap" gap="large">
            <Row gutter={[16, 16]}>
              <Col xs={12} lg={6}>
                <AnswerCard
                  image={KhaosoiImage}
                  title={translations[language].question.food.a1.h1}
                  description={translations[language].question.food.a1.d1}
                  onClick={() => handleClick("KHAOSOI")}
                />
              </Col>
              <Col xs={12} lg={6}>
                <AnswerCard
                  image={TomyumImage}
                  title={translations[language].question.food.a2.h1}
                  description={translations[language].question.food.a2.d1}
                  onClick={() => handleClick("TOM_YUM_GOONG")}
                />
              </Col>
              <Col xs={12} lg={6}>
                <AnswerCard
                  image={SomtumImage}
                  title={translations[language].question.food.a3.h1}
                  description={translations[language].question.food.a3.d1}
                  onClick={() => handleClick("SOM_TUM")}
                />
              </Col>
              <Col xs={12} lg={6}>
                <AnswerCard
                  image={GangtaiplaImage}
                  title={translations[language].question.food.a4.h1}
                  description={translations[language].question.food.a4.d1}
                  onClick={() => handleClick("GANG_TAI_PLA")}
                />
              </Col>
            </Row>
          </Flex>
        </ContentCenter>
      </Modal>
    </MainBackground>
  );
};

export default QuestionFood;
