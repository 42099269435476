import { Button } from 'antd';
import React from 'react';
// import PropTypes from 'prop-types'; // Import PropTypes
import './style.css';

const ButtonBack = (props) => {

    return (
        <Button
            className='back-style'
            size={props?.size || "middle"}
            onClick={props.onClick}
            style={{ height: props.height ?? null }}
            disabled={props.disabled}
        >
          {props.text}
        </Button>
      );
}



export default ButtonBack;