import styled, { keyframes } from "styled-components";

const moveRight = keyframes`
    0% {
      right: 100vw;
      transform: translateY(-20%);
    }

    100% {
      right: -20vw;
      transform: translateY(-20%);
    }
    `;

export const PagodaAnimation = styled.div`
  position: absolute;
  z-index: 5;
  right: calc(100vw + 8rem);
  animation: ${(props) => (props?.isMobile ? "40s" : "100s")} linear 1 normal
    none running ${moveRight};
  animation-delay: ${(props) => (props?.delay ? `${props?.delay}s` : "0s")};
  animation-fill-mode: forwards;
  transition: 4s;
  /* animation-iteration-count: infinite; */
`;
