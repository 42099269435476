import React from 'react'
import './style.css';
import Copyright from '../copyright/Copyright';

const MainBackground = (props) => {
    return (
        <div className={props.className}>
            {props.children}
            { props?.showCopyright && <Copyright/> }
        </div>
      );
}

export default MainBackground;