import React, { useState } from "react";
import HeaderTitle from "../../components/header-title/HeaderTitle";
import { Col, Input, Row, Space, Grid, Modal, Flex } from "antd";
import ContentCenter from "../../components/content-center/ContentCenter";
import CustomButton from "../../components/button/CustomButton";
import { USER_ICONS, USER_ICONS_STYLE } from "../../assets/data/avatar/avatar";
import AnimateClickable from "../../components/avatar/AnimateClickable";
import ReactGA from "react-ga4";
import CustomColorPicker from "../../components/avatar/CustomColorPicker";
import MainBackground from "../../components/main-background/MainBackground";
import { useMain } from "../../context/MainContext";
import { usePagodaContext } from "../../context/PagodaContext";
import "./style.css";

const Avatar = () => {
  const { language, translations, goNextPage } = useMain();
  const { pagodaPayload } = usePagodaContext();
  const screens = Grid.useBreakpoint();

  const [selectedImage, setSelectedImage] = useState(
    USER_ICONS_STYLE.icon1?.icon
  );
  const [color, setColor] = useState(USER_ICONS_STYLE.icon1);
  const [inputValue, setInputValue] = useState("");

  const handleChangeUserIcon = (e) => {
    setSelectedImage(e);
    setColor(USER_ICONS_STYLE[e]);
  };

  const handleChangeSkin = (hex) => {
    setColor((prep) => {
      return {
        ...prep,
        face: hex,
        neck: darkerColor(hex, -30),
      };
    });
  };

  const handleChangeHair = (hex) => {
    setColor((prep) => {
      return {
        ...prep,
        hairType1: hex,
        hairType2: darkerColor(hex, 40),
        hairType3: darkerColor(hex, 60),
        hairType4: darkerColor(hex, -20),
      };
    });
  };

  const darkerColor = (hex, add) => {
    let rgb = [
      parseInt(hex.slice(1, 3), 16),
      parseInt(hex.slice(3, 5), 16),
      parseInt(hex.slice(5, 7), 16),
    ];

    rgb = rgb.map((item) => {
      item = item + add;
      item = item > 255 ? 255 : item;
      item = item < 0 ? 0 : item;
      return item;
    });

    return (
      "#" +
      (0 | ((1 << 8) + rgb[0])).toString(16).substring(1) +
      (0 | ((1 << 8) + rgb[1])).toString(16).substring(1) +
      (0 | ((1 << 8) + rgb[2])).toString(16).substring(1)
    );
  };

  const handleClick = () => {
    pagodaPayload.name = inputValue;
    pagodaPayload.avatar.icon = selectedImage;
    pagodaPayload.avatar.face = color.face;
    pagodaPayload.avatar.neck = color.neck;
    pagodaPayload.avatar.hairType1 = color.hairType1 ? color.hairType1 : "";
    pagodaPayload.avatar.hairType2 = color.hairType2 ? color.hairType2 : "";
    pagodaPayload.avatar.hairType3 = color.hairType3 ? color.hairType3 : "";
    pagodaPayload.avatar.hairType4 = color.hairType4 ? color.hairType4 : "";
    ReactGA.event({
      category: "user action",
      action: "select avatar",
      label: selectedImage,
    });
    goNextPage();
  };

  const handleChange = (e) => {
    const regex = /^[a-zA-Zก-๙\s]*$/;

    if (regex.test(e.target.value) && e.target.value.length <= 40) {
      setInputValue(e.target.value);
    }
  };

  return (
    <MainBackground className="bg-container">
      <Modal
        className="avatar-wrapper"
        open={true}
        closable={false}
        width={1160}
        footer={null}
      >
        <ContentCenter>
          <div
            className="max-container"
            style={{
              marginTop:
                screens.xs ||
                (screens.sm && !screens.md && !screens.lg && !screens.xl)
                  ? "0px"
                  : "94px",
            }}
          >
            <Col xs={24} align="middle">
              <HeaderTitle title={translations[language].avatar.m1.h1} />
            </Col>

            <Flex justify="center" wrap="wrap" gap="small">
              <Row>
                <Col
                  md={{ span: 24, order: 1 }}
                  lg={{ span: 14, push: 1, order: 1 }}
                  className="hide-767"
                  style={{ padding: "0 20px" }}
                >
                  <Row gutter={[16, 16]}>
                    {USER_ICONS?.map((image, index) => (
                      <Col
                        xs={8}
                        lg={8}
                        key={index}
                        align={screens.xs ? "middle" : ""}
                        style={{textAlign: 'center'}}
                      >
                        <div
                          onClick={() => handleChangeUserIcon(image?.key)}
                          className="bouncein"
                          style={{ "--delay": `${index * 100}ms` }}
                        >
                          <AnimateClickable
                            className={`avatar-big-icon ${
                              selectedImage === image?.key
                                ? "avatar-big-icon-select"
                                : ""
                            }`}
                          >
                            <img
                              className="max-width-image"
                              src={image?.image}
                              alt={image?.key}
                            />
                          </AnimateClickable>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Col>
                <Col
                  xs={{ span: 24, order: 2 }}
                  lg={{ span: 14, push: 1, order: 1 }}
                  className="show-767"
                  style={{ overflowX: "scroll" }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    {USER_ICONS?.map((image, index) => (
                      <Col
                        xs={8}
                        lg={7}
                        key={index}
                        align={screens.xs ? "middle" : ""}
                      >
                        <div
                          onClick={() => handleChangeUserIcon(image?.key)}
                          key={index}
                          className="bouncein"
                          style={{ "--delay": `${index * 100}ms` }}
                        >
                          <AnimateClickable className={`avatar-icon`}>
                            <img
                              className={`avatar-img ${
                                selectedImage === image?.key
                                  ? "avatar-icon-select"
                                  : ""
                              }`}
                              style={{ maxWidth: "160px" }}
                              src={image?.image}
                              alt={image?.key}
                            />
                          </AnimateClickable>
                        </div>
                      </Col>
                    ))}
                  </div>
                </Col>
                <Col
                  xs={{ span: 24, order: 2 }}
                  lg={{ span: 10, order: 2, pull: 1 }}
                >
                  <Row
                    justify="center"
                    align="middle"
                    style={{
                      backgroundColor: "white",
                      borderRadius: 10,
                      height: "100%",
                      padding: 10,
                    }}
                  >
                    <Col xs={14} align="middle" style={{ padding: "1rem" }}>
                      {React.cloneElement(
                        USER_ICONS.find((i) => i?.key === selectedImage)?.icon,
                        { custom: color }
                      )}
                    </Col>
                    <Col xs={10} align="bottom">
                      <Space direction="vertical">
                        <CustomColorPicker
                          value={color.face}
                          text={translations[language].avatar.m1.d1}
                          onChange={(_, hex) => handleChangeSkin(hex)}
                        />
                        <CustomColorPicker
                          value={color.hairType1}
                          text={translations[language].avatar.m1.d2}
                          onChange={(_, hex) => handleChangeHair(hex)}
                        />
                      </Space>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Flex>

            <Col xs={24} align="middle" style={{ margin: "50px 0 30px 0" }}>
              <Input
                showCount
                maxLength={40}
                size="large"
                placeholder={translations[language].avatar.placeholder.h1}
                onChange={handleChange}
                value={inputValue}
              />
            </Col>
            <Col xs={24} align="middle">
              <CustomButton
                style="pink-style"
                size="large"
                text={translations[language].avatar.button.next}
                onClick={() => handleClick()}
                disabled={inputValue === ""}
              />
            </Col>
          </div>
        </ContentCenter>
        
        
      </Modal>
    </MainBackground>
  );
};

export default Avatar;
