import React from "react";
import "./style.css";

const Copyright = (props) => {
  return (
    <div
      className={props?.getName? props?.getName : "footer"}
      style={{
        color: props?.textColor ? "white" : "black",
      }}
    >
      Copyright © 2024 Accenture. All Rights Reserved.
    </div>
  );
};

export default Copyright;
