import "./style.css";
import { motion } from "framer-motion";
import { Image } from "antd";
import React, { useState } from "react";
import getPagodaImg from "../../pages/parade/GetPagodaImg";
import { USER_ICONS, USER_ICONS_STYLE } from "../../assets/data/avatar/avatar";

const PagodaParade = ({ pagoda }) => {
  const handlePagoda = (_pagoda) => {
    return getPagodaImg(_pagoda.pagodaType, _pagoda.flag, _pagoda.flower);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const isShow = false;
  const handleOpen = (sessionId) => {
    setIsOpen(!isOpen);
    setSelectedId(sessionId);
    setTimeout(() => {
      setIsOpen(false);
    }, 5000);
  };

  const mapAvatarIcon = (pagoda) => {
    let idx = 0;
    switch (pagoda.avatar.icon) {
      case "icon1":
        idx = 0;
        break;
      case "icon2":
        idx = 1;
        break;
      case "icon3":
        idx = 2;
        break;
      case "icon4":
        idx = 3;
        break;
      case "icon5":
        idx = 4;
        break;
      case "icon6":
        idx = 5;
        break;
    }
    return USER_ICONS[idx].icon;
  };

  const mapAvatarColor = (pagoda) => {
    return {
      icon: pagoda.avatar.icon,
      face: pagoda.avatar.face,
      neck: pagoda.avatar.neck,
      hairType1: pagoda.avatar.hairType1
        ? pagoda.avatar.hairType1
        : USER_ICONS_STYLE.icon1.hairType1,
      hairType2: pagoda.avatar.hairType2
        ? pagoda.avatar.hairType2
        : USER_ICONS_STYLE.icon1.hairType2,
      hairType3: pagoda.avatar.hairType3
        ? pagoda.avatar.hairType3
        : USER_ICONS_STYLE.icon1.hairType3,
      hairType4: pagoda.avatar.hairType4
        ? pagoda.avatar.hairType4
        : USER_ICONS_STYLE.icon1.hairType4,
    };
  };

  return (
    <div>
      {pagoda && (
        <div
          className="pagoda_group"
        >
          <div className="pagoda_wrapper" key={pagoda.sessionId}>
            <div className="pagoda_sub_group">
              <Image
                width={100}
                src={handlePagoda(pagoda)}
                preview={false}
              />
            </div>
            <div className="avatar_group">
              {!false && (
                <motion.div
                  layoutId={pagoda.sessionId}
                  data-isopen={isOpen && pagoda.sessionId === selectedId}
                  initial={{ borderRadius: 50 }}
                  className="avatar-details"
                  onClick={() => handleOpen(pagoda.sessionId)}
                  style={
                    isOpen && pagoda.sessionId === selectedId && !isShow
                      ? { bottom: "7px", left: "170px" }
                      : {}
                  }
                >
                  <div className="message-box arrow-bottom">
                    <div style={{ display: "flex" }}>
                      <motion.div
                        layout
                        className="detail-icon"
                        data-isopen={
                          isOpen && pagoda.sessionId === selectedId
                        }
                      >
                        {React.cloneElement(mapAvatarIcon(pagoda), {
                          custom: mapAvatarColor(pagoda),
                        })}
                      </motion.div>
                    </div>
                    {isOpen && pagoda.sessionId === selectedId && (
                      <div
                        className={`detail-name`}
                      >
                        <strong>{pagoda?.name}</strong>
                      </div>
                    )}
                    {isOpen && pagoda.sessionId === selectedId && (
                      <div className="detail-wish">
                        {pagoda?.blessingWord}
                      </div>
                    )}
                  </div>
                </motion.div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PagodaParade;