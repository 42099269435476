import { Row } from 'antd';
import React from 'react'
import './style.css'
const ContentCenter = (props) => {

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: props?.minHeight || '0vh', height:props.height }}>
            <Row style={{paddingLeft: 16, paddingRight: 16 ,}} className='wrap-row'>
                <Row justify="center" align="middle" style={{position: 'relative'}} gutter={props.gutter || [16, 16]}>
                    {props.children}
                </Row>
            </Row>
        </div>
    )

    // return (
    //     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: props.padding, margin: props.margin || "0 10px" }}>
    //         <Row>
    //             {props.children}
    //         </Row>
    //     </div>
    // )
}

export default ContentCenter;