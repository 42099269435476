import React from 'react';
export default function Avatar2({ custom }) {
    const face = custom.face;
    const neck = custom.neck;
    const hair = custom.hairType1;
    return (
        <svg width="104" height="180" viewBox="0 0 104 180" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.7068 184.532L20.6725 184.387C20.5249 183.738 20.3912 182.81 20.2971 181.656L20.2742 181.36L20.2532 181.058C20.0454 177.899 20.1317 173.634 20.4934 168.53C21.1381 159.433 22.6312 148.109 24.32 138.264C25.0574 133.966 22.1704 129.883 17.8716 129.146C13.5729 128.408 9.49033 131.295 8.75293 135.594L8.58762 136.566C6.88628 146.649 5.40334 158.031 4.73842 167.413L4.69789 167.998L4.65888 168.585C4.29251 174.24 4.23846 179.057 4.55485 182.939C4.7617 185.476 5.12784 187.664 5.71782 189.558C6.6258 192.473 8.06259 194.848 10.5698 196.488C14.2202 198.874 19.1144 197.85 21.5013 194.2C23.5214 191.11 23.0979 187.129 20.7068 184.532Z" fill="#EBBAAA" />
            <path d="M76.8946 135.529C80.6893 142.256 83.2142 152.599 84.0797 163.955C84.8147 173.599 84.1868 182.825 82.8542 186.638L82.8136 186.752C81.3231 190.851 83.4376 195.382 87.5365 196.872C91.6354 198.363 96.1666 196.249 97.6571 192.15C99.9371 185.88 100.726 174.537 99.8285 162.755C98.8024 149.292 95.7585 136.823 90.6512 127.769C88.5083 123.97 83.6916 122.628 79.8928 124.771C76.094 126.914 74.7517 131.73 76.8946 135.529Z" fill="#EBBAAA" />
            <path d="M15.9587 117.415C15.9587 112.399 26.9508 107.28 34.6346 104.846L44.6662 101.517C46.0632 101.473 55.6619 101.873 57.0456 102.064L72.6266 107.28C80.0097 108.301 86.0698 112.24 85.3262 119.656L82.6685 151.522L83.4053 193.506C83.4206 193.893 66.2905 189.044 65.9033 189.024L36.022 194.206L20.1208 193.491C19.749 193.471 19.5564 192.384 19.5872 192.012L21.7233 147.591L15.9587 117.415Z" fill="#EBBAAA" />
            <path d="M87.4836 112.396C85.7545 110.433 80.06 108.45 77.429 107.704C79.0891 132.868 79.2707 181.812 79.8722 184.371C80.6239 187.569 98.6659 190.875 100.169 189.595C101.673 188.316 103.364 176.267 102.988 169.656C102.613 163.045 89.6449 114.848 87.4836 112.396Z" fill="#322B1F" />
            <path d="M99.9767 189.664C100.916 189.493 103.072 183.403 103.819 181.02L77.5657 176.858C77.2455 179.028 76.6479 183.731 76.8187 185.182C77.0321 186.996 81.9412 188.597 87.2771 189.451C92.6131 190.304 98.8028 189.878 99.9767 189.664Z" fill="#3F3627" />
            <path d="M15.3007 111.542C17.1389 109.58 23.1928 107.596 25.9901 106.85C24.2252 132.016 20.5355 182.988 19.8961 185.548C19.0969 188.747 6.24465 190.73 4.64623 189.451C3.04782 188.171 1.24961 176.121 1.64922 169.51C2.04882 162.898 13.003 113.995 15.3007 111.542Z" fill="#322B1F" />
            <path d="M4.13945 190.223C3.21992 190.053 1.10914 184.002 0.377686 181.634L26.0831 177.498C26.3967 179.654 26.9817 184.327 26.8146 185.769C26.6056 187.572 21.7988 189.162 16.5742 190.01C11.3495 190.859 5.28888 190.435 4.13945 190.223Z" fill="#3F3627" />
            <path d="M41.7848 102.62L42.5318 102.086L53.6306 105.608L58.8599 102.299L60.8875 103.153L63.0219 109.877L50.5358 112.651L40.5042 109.236L41.7848 102.62Z" fill="#EBBAAA" />
            <path d="M48.5687 121.024C47.5419 117.933 48.0552 113.456 48.0552 113.456L43.4345 115.268L37.8894 104.075C31.4888 105.248 31.2149 105.674 26.594 107.806C21.0818 110.349 22.1786 118.892 21.3571 130.511L20.2275 194.149L35.425 196.601L45.5909 196.708L50.1089 124.542C50.1089 124.542 49.5956 124.116 48.5687 121.024Z" fill="#E5EBF9" />
            <path d="M51.9142 120.918C49.36 118.68 49.7858 116.903 50.244 115.162L51.3247 114.309L57.6121 113.67L63.703 104.716C63.703 104.716 72.4463 105.995 75.3935 107.7C78.3406 109.406 80.109 128.06 80.109 128.06L81.4844 194.469H66.1168L48.7704 191.372C48.5085 169.058 46.2233 126.325 48.7704 124.862C52.1107 122.944 52.5037 121.435 51.9142 120.918Z" fill="#DAE2F3" />
            <path d="M58.8599 120.724C59.4493 120.724 59.9271 120.246 59.9271 119.656C59.9271 119.067 59.4493 118.589 58.8599 118.589C58.2705 118.589 57.7927 119.067 57.7927 119.656C57.7927 120.246 58.2705 120.724 58.8599 120.724Z" fill="#DAE2F3" />
            <path d="M42.4251 119.656C43.0145 119.656 43.4923 119.179 43.4923 118.589C43.4923 118 43.0145 117.522 42.4251 117.522C41.8357 117.522 41.3579 118 41.3579 118.589C41.3579 119.179 41.8357 119.656 42.4251 119.656Z" fill="#E5EBF9" />
            <path d="M51.0693 130.328C51.9534 130.328 52.6701 129.612 52.6701 128.727C52.6701 127.843 51.9534 127.127 51.0693 127.127C50.1852 127.127 49.4685 127.843 49.4685 128.727C49.4685 129.612 50.1852 130.328 51.0693 130.328Z" fill="#EDF2FF" />
            <path d="M49.7888 145.696C50.6729 145.696 51.3896 144.931 51.3896 143.988C51.3896 143.045 50.6729 142.281 49.7888 142.281C48.9047 142.281 48.188 143.045 48.188 143.988C48.188 144.931 48.9047 145.696 49.7888 145.696Z" fill="#EDF2FF" />
            <path d="M48.935 160.85C49.8191 160.85 50.5358 160.133 50.5358 159.249C50.5358 158.365 49.8191 157.648 48.935 157.648C48.0509 157.648 47.3342 158.365 47.3342 159.249C47.3342 160.133 48.0509 160.85 48.935 160.85Z" fill="#EDF2FF" />
            <path d="M59.5 107.384C60.234 104.956 59.5 103.222 59.2866 102.154L65.2628 104.396C65.2628 104.396 67.2905 106.957 66.0099 112.826C64.6069 119.257 58.4023 121.791 56.9387 123.498C56.2984 124.245 56.5119 115.174 51.6028 114.214C53.8439 113.68 58.1127 111.973 59.5 107.384Z" fill="#E5EBF9" />
            <path d="M42.4361 108.878C40.9309 106.743 41.7117 103.75 42.4361 102.154L37.1958 103.969C37.1958 103.969 34.1824 109.157 35.8114 114.171C37.8361 120.403 45.049 122.655 44.9863 121.897C44.346 114.171 46.9049 114.459 47.9744 113.467C46.1602 113.04 44.4365 111.714 42.4361 108.878Z" fill="#DAE2F3" />
            <path d="M46.626 131.349C45.0826 124.778 43.8914 112.983 39.9247 103.222C39.9247 103.222 35.8085 103.648 31.8853 104.691C27.9621 105.733 23.3399 107.63 23.3399 107.63C20.8423 111.475 21.0543 121.936 20.349 143.944C19.6438 165.951 18.4009 187.98 17.6663 197.89C20.7516 198.888 40.8309 203.459 50.9684 199.909C50.9684 197.348 51.8612 194.906 51.9669 192.012C52.3116 182.562 51.137 173.566 50.9684 157.601C50.8194 143.515 48.8938 141.005 46.626 131.349Z" fill="#483E2D" />
            <path d="M28.7649 107.834L39.8637 102.795C41.5712 107.834 43.3075 113.642 45.1996 119.78C47.334 126.705 50.9625 139.729 50.9625 165.119L28.7649 123.362L36.0218 115.388L28.7649 118.326V107.834Z" fill="#5A4D37" />
            <path d="M60.3028 133.932C60.3028 127.471 60.7666 112.36 64.7079 103.008L75.8208 105.281C78.8023 105.891 81.3521 107.767 81.5852 110.802C82.0767 117.2 81.0129 128.896 81.5393 144.739C82.24 165.822 82.8888 187.83 83.6188 197.323C77.4336 199.808 63.1917 203.415 53.1195 200.015C53.1195 197.805 52.3731 196.495 51.4133 192.355C51.3697 184.931 50.8458 176.851 50.9866 163.992C51.1345 150.497 60.3028 140.393 60.3028 133.932Z" fill="#483E2D" />
            <path d="M73.3737 106.726L64.4093 103.008C63.271 107.139 60.5386 115.762 58.6464 121.805C56.5121 128.621 51.8164 135.644 51.8164 160.637L73.3737 122.424L66.1168 114.575L73.3737 117.467V106.726Z" fill="#5A4D37" />
            <path d="M48.2947 173.443C49.2967 173.443 50.1089 172.631 50.1089 171.629C50.1089 170.627 49.2967 169.814 48.2947 169.814C47.2927 169.814 46.4805 170.627 46.4805 171.629C46.4805 172.631 47.2927 173.443 48.2947 173.443Z" fill="#332918" />
            <rect x="42.5205" y="89.5618" width="16.7197" height="25.2995" rx="8.09166" fill={neck} />
            <path d="M81.3278 75.3751C88.3141 75.3751 93.9775 69.7118 93.9775 62.7255C93.9775 55.7392 88.3141 50.0757 81.3278 50.0757C74.3416 50.0757 68.678 55.7392 68.678 62.7255C68.678 69.7118 74.3416 75.3751 81.3278 75.3751Z" fill={neck} />
            <path d="M21.7785 76.0155C14.7921 76.0155 9.12866 70.3521 9.12866 63.3658C9.12866 56.3796 14.7921 50.716 21.7785 50.716C28.7647 50.716 34.4281 56.3796 34.4281 63.3658C34.4281 70.3521 28.7647 76.0155 21.7785 76.0155Z" fill={neck} />
            <path d="M17.8796 39.297C17.8796 21.2026 32.548 6.53424 50.6424 6.53424C68.7369 6.53424 83.4052 21.2026 83.4052 39.297V54.7713L82.8791 71.7546C82.3397 89.1699 68.0661 103.008 50.6424 103.008C33.809 103.008 19.8989 89.8755 18.9322 73.0698L17.8796 54.7713V39.297Z" fill={face} />
            <path d="M61.5278 58.6132V62.4551C61.5278 63.8696 62.6745 65.0164 64.0891 65.0164C65.5036 65.0164 66.6504 63.8696 66.6504 62.4551V58.6132C66.6504 57.1987 65.5036 56.0519 64.0891 56.0519C62.6745 56.0519 61.5278 57.1987 61.5278 58.6132Z" fill="black" />
            <path d="M33.9941 58.6132V62.4551C33.9941 63.8696 35.1409 65.0164 36.5554 65.0164C37.9699 65.0164 39.1167 63.8696 39.1167 62.4551V58.6132C39.1167 57.1987 37.9699 56.0519 36.5554 56.0519C35.1409 56.0519 33.9941 57.1987 33.9941 58.6132Z" fill="black" />
            <path d="M60.9702 48.7193L73.1913 48.1852C74.6045 48.1235 75.7001 46.9278 75.6383 45.5146C75.5765 44.1014 74.3809 43.0059 72.9677 43.0676L60.7466 43.6016C59.3334 43.6634 58.2379 44.8591 58.2996 46.2723C58.3614 47.6855 59.557 48.781 60.9702 48.7193Z" fill={hair} />
            <path d="M41.5627 43.5926L29.3305 43.4854C27.916 43.473 26.7593 44.6097 26.7469 46.0241C26.7345 47.4386 27.8712 48.5954 29.2857 48.6077L41.5178 48.7149C42.9323 48.7273 44.089 47.5907 44.1014 46.1762C44.1138 44.7617 42.9772 43.605 41.5627 43.5926Z" fill={hair} />
            <mask id="mask0_438_323" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="18" y="16" width="66" height="88">
                <path fillRule="evenodd" clipRule="evenodd" d="M18.9468 48.9014C18.9468 31.1608 33.3285 16.7791 51.0692 16.7791C68.81 16.7791 83.1917 31.1608 83.1917 48.9014V70.8841C83.1917 88.6247 68.81 103.006 51.0692 103.006C33.3285 103.006 18.9468 88.6247 18.9468 70.8841V48.9014Z" fill="white" />
            </mask>
            <g mask="url(#mask0_438_323)">
                <path d="M31.4552 80.046C24.5126 80.1475 21.8552 70.9029 19.3094 67.9002C18.6447 73.7012 17.2791 85.5207 17.1341 86.3908C16.989 87.261 21.787 97.9928 24.204 103.25H66.805L82.0325 85.3032C82.5764 77.7497 85.4769 64.4559 83.3015 69.5317C81.1261 74.6076 74.4791 80.046 69.5242 80.046C69.5242 80.046 58.4661 77.6894 49.7646 77.6894C41.0631 77.6894 31.4552 80.046 31.4552 80.046Z" fill="#D5CCC9" />
            </g>
            <path d="M66.5672 82.9685V99.7021C64.4513 101.523 58.5493 103.459 51.8678 103.459C43.5158 103.459 38.3933 100.954 35.832 99.7021V82.9685C36.5002 81.261 40.5091 77.846 51.1996 77.846C61.8901 77.846 65.899 81.261 66.5672 82.9685Z" fill={hair} />
            <path d="M39.2471 82.1147H63.1522C63.1522 86.0989 60.7617 94.0673 51.1996 94.0673C41.6376 94.0673 39.2471 86.0989 39.2471 82.1147Z" fill={face} />
            <path d="M43.9747 85.5985C48.1558 87.9213 53.0905 87.8611 58.4148 85.683C59.2549 85.3393 59.6573 84.3797 59.3136 83.5397C58.97 82.6996 58.0104 82.2972 57.1703 82.6409C52.7034 84.4683 48.7941 84.516 45.571 82.7253C44.7775 82.2846 43.777 82.5704 43.3363 83.3638C42.8955 84.1572 43.1813 85.1577 43.9747 85.5985Z" fill="#C99584" />
            <path d="M53.8873 61.9453L53.5522 57.6428C53.5522 57.6428 55.7274 52.7555 64.4681 51.7241C73.2089 50.6928 77.0732 56.0674 77.0732 56.0674L76.3968 62.3544C75.9445 66.5572 72.3402 69.7082 68.1145 69.5945L61.7553 69.4233C57.6054 69.3117 54.2096 66.0843 53.8873 61.9453Z" fill="white" fillOpacity="0.44" />
            <path d="M46.2747 61.7788L46.5603 57.6887C46.5603 57.6887 44.3875 52.9094 35.8011 51.9658C27.2147 51.0224 23.4644 56.3276 23.4644 56.3276L24.1617 62.3543C24.6477 66.5535 28.2772 69.6752 32.5016 69.5277L38.4669 69.3196C42.6158 69.1747 45.9857 65.9202 46.2747 61.7788Z" fill="white" fillOpacity="0.44" />
            <path d="M54.7934 65.1704L53.5333 57.0228C53.4394 56.4154 53.6136 55.7981 54.0853 55.4041C55.3648 54.335 58.4981 52.2749 64.2804 51.527C70.2267 50.758 74.4829 52.7722 76.2523 53.8379C76.8429 54.1935 77.1409 54.8577 77.1025 55.546L76.8781 59.5792" stroke="black" strokeWidth="1.70751" />
            <path d="M45.4019 65.0865L46.5719 57.0982C46.6609 56.4901 46.4814 55.8737 46.0059 55.4844C44.7327 54.4422 41.6434 52.4572 35.9841 51.7712C30.1611 51.0656 26.0078 53.0606 24.2695 54.1229C23.6813 54.4823 23.3893 55.1484 23.4333 55.8365L23.6836 59.7726" stroke="black" strokeWidth="1.70751" />
            <path d="M46.0425 57.5274C48.2836 56.4602 50.8448 56.0332 53.5128 57.5274" stroke="black" strokeWidth="1.49407" />
            <path d="M48.768 64.3352C47.4721 66.6218 46.7167 69.1209 46.8141 71.1599C46.9478 73.9607 48.706 75.8438 51.6663 75.8438V72.2153C50.7415 72.2153 50.4839 71.9395 50.4384 70.9868C50.3779 69.7207 50.9396 67.8625 51.9248 66.1242L48.768 64.3352Z" fill={neck} />
            <path d="M15.5448 35.7512C15.3971 22.3089 27.2229 17.9045 25.1295 20.4382C23.0359 22.9718 27.994 29.2515 25.1295 31.2344C22.2648 33.2174 25.4598 39.6072 23.9175 42.0308C22.3751 44.4545 19.913 43.9036 18.5194 48.6407C17.1257 53.3778 18.299 62.6002 18.299 62.6002C18.4092 62.852 18.299 63.1825 18.0787 63.1825C17.8584 63.1825 17.5279 63.0723 17.4383 62.6002C17.4383 62.6002 15.7651 55.8012 15.5448 35.7512Z" fill="#B4A9A3" />
            <path d="M85.7183 36.0892C85.8547 23.6763 74.9389 19.6094 76.8712 21.949C78.8035 24.2888 74.227 30.0872 76.8712 31.9184C79.5154 33.7495 76.5663 39.6498 77.99 41.8879C79.4136 44.1258 81.6863 43.6172 82.9728 47.9915C84.2591 52.3659 83.1762 60.8819 83.1762 60.8819C83.0744 61.1145 83.1762 61.4197 83.3795 61.4197C83.5829 61.4197 83.8878 61.3179 83.9707 60.8819C83.9707 60.8819 85.5152 54.6038 85.7183 36.0892Z" fill="#B4A9A3" />
            <path d="M51.7889 27.7087C33.5013 27.7087 20.8959 30.6832 18.0785 36.7837C15.2612 42.8842 15.906 44.7843 15.7651 43.1319C15.6241 41.4794 15.4346 39.6343 15.4346 36.3016C15.4346 14.6679 30.5273 11.4039 44.1876 12.726C57.848 14.0481 68.5034 27.7087 51.7889 27.7087Z" fill={hair} />
            <path d="M49.4366 27.6008C66.2146 27.6008 83.0451 21.2852 83.7367 33.5475C86.3807 37.7337 85.3892 42.5812 85.7197 35.7508C87.143 6.33686 42.3147 -13.4647 19.4004 20.7965C19.4004 20.7965 32.657 27.6008 49.4366 27.6008Z" fill={hair} />
            <path d="M41.8741 29.1411C33.3914 27.0757 22.3749 31.7851 15.5447 32.9969C15.5447 18.1245 31.4084 2.92173 49.5856 2.48107C67.7629 2.04041 78.9997 14.0484 81.864 19.9973C82.978 22.3109 80.4319 26.5914 74.4829 29.1411C67.5425 32.1155 54.5431 32.2257 41.8741 29.1411Z" fill={hair} />
            <path d="M41.6585 33.6229C49.816 28.6377 53.562 27.6156 57.4899 25.3495C54.7708 24.141 47.7112 20.7648 44.0856 23.6653C42.5343 25.6894 39.7672 29.0907 41.6585 33.6229Z" fill={hair} />
            <path d="M26.204 35.9015C38.3273 28.877 43.5768 31.1431 47.5047 28.877C44.7854 27.6684 50.677 19.0328 31.3025 23.2118C25.5243 24.4582 24.3127 31.3696 26.204 35.9015Z" fill={hair} />
            <path d="M78.0834 10.2697L80.2766 18.0607L51.366 13.5835L18.3262 19.6233L18.3959 16.4176L20.7526 15.438L20.8074 12.9192L23.7784 12.9838L23.863 9.09118L27.6827 9.17422L28.5964 6.21594L31.9917 6.28975L34.0725 3.35687L37.5541 4.34889L38.6549 2.54013L42.2626 2.61855L42.3073 0.557739L47.1436 2.72463L48.4616 0.691508L50.5388 2.79845L52.7058 0.783765L56.6931 2.93221L59.2844 0.926759L61.9984 3.04753L63.9082 3.08904L65.9906 4.967L66.8792 3.15362L70.4023 7.12467L72.7565 6.25948L75.7367 10.7914L78.0834 10.2697Z" fill={hair} />
        </svg>
    );
}