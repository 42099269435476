import React, { createContext, useContext, useState } from 'react';


const PagodaContext = createContext();

export const PagodaProvider = ({ children }) => {

  const [pagodaPayload] = useState(
    {
      "name": "",
      "location": "",
      "celebrateWith": "",
      "food": "",
      "activity": "",
      "pagodaType": "",
      "flag": "",
      "flower": "",
      "avatar": {
          "icon": "",
          "face": "",
          "neck": "",
          "hairType1": "",
          "hairType2": "",
          "hairType3": "",
          "hairType4": ""
      }
  }
  );


  return (
    <PagodaContext.Provider value={{ pagodaPayload }}>
      {children}
    </PagodaContext.Provider>
  );
};

export const usePagodaContext = () => useContext(PagodaContext);