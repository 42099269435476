import React, { useState } from "react";
import IconImage from "../../components/icon-image/IconImage";
import MainBackground from "../../components/main-background/MainBackground";
import ContentCenter from "../../components/content-center/ContentCenter";
import CustomButton from "../../components/button/CustomButton";
import koinoboriFlag from "../../assets/images/flag/koi-nobori-flag.svg";
import accentureFlag from "../../assets/images/flag/accenture-flag.svg";
import twofingersFlag from "../../assets/images/flag/two-fingers-flag.svg";
import prideFlag from "../../assets/images/flag/pride-flag.svg";
import orangeFlag from "../../assets/images/flag/orange-flag.svg";
import { Col, Modal, Row, Grid } from "antd";
import { useMain } from "../../context/MainContext";
import { usePagodaContext } from "../../context/PagodaContext";
import HeaderTitle from "../../components/header-title/HeaderTitle";
import ReactGA from "react-ga4";
import "./style.css";

const PagodaFlag = () => {
  var flagImages = [
    {
      key: "CARP",
      directory: koinoboriFlag,
      placeHolder: "Koi Nobori Flag",
    },
    {
      key: "ACN",
      directory: accentureFlag,
      placeHolder: "Accenture Flag",
    },
    {
      key: "PEACE",
      directory: twofingersFlag,
      placeHolder: "Two Fingers Flag",
    },
    {
      key: "PRIDE",
      directory: prideFlag,
      placeHolder: "Pride Flag",
    },
    {
      key: "ORANGE",
      directory: orangeFlag,
      placeHolder: "Orange Flag",
    },
  ];

  const { language, translations, goNextPage } = useMain();
  const { pagodaPayload } = usePagodaContext();

  const DEFAULT_FLAG = "CARP";
  const [selectedFlag, setSelectedFlag] = useState(DEFAULT_FLAG);

  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();

  const handleSelectFlag = (key) => {
    ReactGA.event({
      category: "user action",
      action: "select flag",
      label: selectedFlag,
    });
    pagodaPayload.flag = selectedFlag;
    setSelectedFlag(key);
  };

  const BTN_NEXT_STYLE = "pagoda-next-style";
  const BTN_NEXT_STYLE_XS = "pagoda-next-style-xs";

  const handleBtnHeight = (xs) => {
    return xs ? BTN_NEXT_STYLE_XS : BTN_NEXT_STYLE;
  };

  const handleClick = () => {
    pagodaPayload.flag = selectedFlag;
    goNextPage();
  };

  const ResposiveFlag = () => {
    //called when small screen
    if (xs) {
      return (
        <Row
          style={{
            display: "grid",
            gridTemplateColumns: "50% 50%",
            gridGap: "24px",
            width: "100%",
            justifyContent: "center",
          }}
        >
          {flagImages.map((image, index) => {
            return (
              <div
                key={index}
                style={{ padding: "none", width: "fit-content" }}
                onClick={() => {
                  handleSelectFlag(image.key);
                }}
              >
                <IconImage
                  width={112}
                  height={112}
                  imageSrc={image.directory}
                  altText={image.placeHolder}
                  isBorderImage={selectedFlag === image.key}
                />
              </div>
            );
          })}
        </Row>
      );
    }
    return (
      //called when full screen
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {flagImages.map((image, index) => {
          return (
            <Col
              key={index}
              align="middle"
              style={{ padding: "1rem" }}
              onClick={() => {
                handleSelectFlag(image.key);
              }}
            >
              <IconImage
                imageSrc={image.directory}
                altText={image.placeHolder}
                isBorderImage={selectedFlag === image.key}
              />
            </Col>
          );
        })}
      </Row>
    );
  };

  return (
    <MainBackground className="bg-container">
      <Modal
        className="flag-select-wrapper"
        open={true}
        closable={false}
        width={1160}
        minHeight={522}
        footer={null}
      >
        <div style={{ width: "100%" }}>
          <ContentCenter minHeight={xs ? "60vh" : "90vh"}>
            <Col>
              <Row style={{ display: "flex", justifyContent: "center" }}>
                <HeaderTitle
                  isMobile={xs}
                  title={translations[language].pagoda.flag_page.title}
                />
              </Row>
              <ResposiveFlag />
              <Row style={{ display: "flex", justifyContent: "center" }}>
                <Col
                  align="middle"
                  className={xs && "bottom-button"}
                  style={{
                    marginTop: !xs && "100px",
                    width: xs ? "342px" : "558px",
                  }}
                >
                  <CustomButton
                    style={handleBtnHeight(xs)}
                    isCloseHover={xs}
                    text={translations[language].pagoda.flag_page.next}
                    onClick={() => handleClick()}
                    size="large"
                  />
                </Col>
              </Row>
            </Col>
          </ContentCenter>
        </div>
      </Modal>
    </MainBackground>
  );
};

export default PagodaFlag;
