import React from "react";
import { Typography } from "antd";
import "./style.css";

const { Title } = Typography;

const HeaderTitle = ({ title, isMobile }) => {
  return (
    <Title
      style={{ color: "#FFFFFF", marginBottom: isMobile ? 40 : 50 }}
      level={1}
    >
      {title}
    </Title>
  );
};

export default HeaderTitle;
