import React from "react";
import { Col, Flex, Modal, Row } from "antd";
import AnswerCard from "../../components/card/AnswerCard";
import ReactGA from "react-ga4";
import CityImage from "../../assets/images/location/City.svg";
import CulturalImage from "../../assets/images/location/CulturalPlaces.png";
import BeachImage from "../../assets/images/location/Beach.svg";
import SpaceImage from "../../assets/images/location/OuterSpace.png";
import MainBackground from "../../components/main-background/MainBackground";
import ContentCenter from "../../components/content-center/ContentCenter";
import { useMain } from "../../context/MainContext";
import { usePagodaContext } from "../../context/PagodaContext";
import HeaderTitle from "../../components/header-title/HeaderTitle";
import "./style.css";

const QuestionLocation = () => {
  const { language, translations, goNextPage } = useMain();
  const { pagodaPayload } = usePagodaContext();

  const handleClick = (value) => {
    pagodaPayload.location = value;
    ReactGA.event({
      category: "user action",
      action: "select location",
      label: value
    });
    goNextPage();
  };

  return (
    <MainBackground className="bg-container">
      <Modal open={true} closable={false} width={1400} footer={null}>
        <ContentCenter>
          <Col xs={24} align="middle">
            <HeaderTitle
              title={translations[language].question.location.title}
            />
          </Col>
          <Flex justify="center" wrap="wrap" gap="large">
            <Row gutter={[16, 16]}>
              <Col xs={12} lg={6}>
                <AnswerCard
                  image={CityImage}
                  title={translations[language].question.location.a1.h1}
                  description={translations[language].question.location.a1.d1}
                  onClick={() => handleClick("CITY")}
                />
              </Col>
              <Col xs={12} lg={6}>
                <AnswerCard
                  image={CulturalImage}
                  title={translations[language].question.location.a2.h1}
                  description={translations[language].question.location.a2.d1}
                  onClick={() => handleClick("CULTURAL_PLACES")}
                />
              </Col>
              <Col xs={12} lg={6}>
                <AnswerCard
                  image={BeachImage}
                  title={translations[language].question.location.a3.h1}
                  description={translations[language].question.location.a3.d1}
                  onClick={() => handleClick("BEACH")}
                />
              </Col>
              <Col xs={12} lg={6}>
                <AnswerCard
                  image={SpaceImage}
                  title={translations[language].question.location.a4.h1}
                  description={translations[language].question.location.a4.d1}
                  onClick={() => handleClick("OUTER_SPACE")}
                />
              </Col>
            </Row>
          </Flex>
        </ContentCenter>
      </Modal>
    </MainBackground>
  );
};

export default QuestionLocation;
